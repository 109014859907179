import { CSSProperties, useMemo } from 'react'
import { EditorView } from '@codemirror/view'
import { Extension } from '@codemirror/state'
import { useTheme } from 'styled-components'
import { useDarkMode } from 'usehooks-ts'

export interface EditorThemeCustomStyles {
  // for all standard texts
  textColor?: CSSProperties['color']
  // for headlines, with fallback to `textColor`
  headlineColor?: CSSProperties['color']
  backgroundColor?: CSSProperties['backgroundColor']
  lineHeight?: CSSProperties['lineHeight']
  lineHeightDense?: CSSProperties['lineHeight']
  selectionMatch: CSSProperties['backgroundColor']
  activeLineGutter: CSSProperties['backgroundColor']
  activeLine: CSSProperties['backgroundColor']
  activeSelection: CSSProperties['backgroundColor']
  // defaults to `100%`, set no width at all with `null`
  width: CSSProperties['width'] | null
}

//  # TODO: adapt previous theming
const DisabledTheme = {
  primary: '#ececec',
  secondary: '#8b8b8b',
}

const SignicatJsonTheme = {
  light: {
    default: '#2A0760',
    string: '#7D18FF',
    number: '#E02569',
    colon: '#00AC82',
    keys: '#1A6351',
    keys_whiteSpace: '#E02569',
    primitive: '#1A6351',
    background: '#F2EDFE',
    background_warning: '#F2EDFE',
  },
  dark: {
    default: '#F2EDFE',
    string: '#b39ad4',
    number: '#E02569',
    colon: '#00AC82',
    keys: '#087e57',
    keys_whiteSpace: '#E02569',
    primitive: '#087e57',
    background: '#252229',
    background_warning: '#252229',
  },
}

export const useEditorTheme = (readOnly?: boolean, width?: string): Extension => {
  const theme = useTheme()
  const variant = 'standard'
  const borderRadius = '0'
  const spacing = '4px'
  const editorWidth = width || '100%'

  const { isDarkMode } = useDarkMode()

  /* eslint-disable */
  return useMemo(
    () =>
      EditorView.theme(
        {
          '&': {
            color: theme.json.textColor,
            backgroundColor: theme.json.backgroundColor,
            ...(typeof editorWidth === 'undefined' || editorWidth === null
              ? {
                  width: '100%',
                }
              : {
                  width: editorWidth,
                }),
          },
          '&.cm-editor': {
            outline: '1px solid ' + (variant === 'standard' ? theme.json.borderDefault : 'transparent'),
            borderRadius: borderRadius,
          },
          ...(variant !== 'standard' || readOnly
            ? {}
            : {
                '&.cm-editor:hover': {
                  outline: '1px solid ' + theme.json.textColor,
                },
              }),
          ...(variant === 'standard'
            ? {
                // `invalid` is a custom class used by `WidgetCode` when not valid to schema
                '&.cm-editor.invalid': {
                  outline: '1px solid ' + theme.json.borderInvalid,
                },
                '&.cm-editor.cm-focused': {
                  outline: '2px solid ' + theme.json.borderFocused,
                },
                // `invalid` is a custom class used by `WidgetCode` when not valid to schema
                '&.cm-editor.cm-focused.invalid': {
                  outline: '2px solid ' + theme.json.borderInvalid,
                },
              }
            : variant === 'embed'
            ? {
                '&.cm-editor.invalid': {
                  outline: '1px solid ' + theme.json.borderInvalid,
                },
                '&.cm-editor.cm-focused': {
                  outline: 0,
                },
                // `invalid` is a custom class used by `WidgetCode` when not valid to schema
                '&.cm-editor.cm-focused.invalid': {
                  outline: '2px solid ' + theme.json.borderInvalid,
                },
              }
            : {}),
          '& .cm-content': {
            caretColor: theme.json.textColor,
            padding: theme.json.contentPadding,
            lineHeight: theme.json.lineHeight,
          },
          '& .cm-scroller': {
            lineHeight: theme.json.lineHeight,
          },
          '&.cm-focused .cm-cursor': {
            borderLeftColor: theme.json.textColor,
          },
          '&.cm-editor .cm-line': {
            padding: theme.json.linePadding,
          },
          '&.cm-editor.cm-focused .cm-activeLine': {
            backgroundColor: theme.json.activeLine,
          },
          '&.cm-editor .cm-activeLine': {
            backgroundColor: 'transparent',
          },
          '&.cm-editor .cm-selectionMatch': {
            backgroundColor: theme.json.selectionMatch,
          },
          '&.cm-editor.cm-focused .cm-gutterElement.cm-activeLineGutter': {
            backgroundColor: theme.json.activeLineGutter,
          },
          '& .cm-gutterElement.cm-activeLineGutter': {
            backgroundColor: 'transparent',
          },
          '&.cm-focused .cm-selectionBackground, ::selection': {
            backgroundColor: theme.json.activeSelection,
          },
          '& .cm-gutters': {
            backgroundColor: theme.json.backgroundColor,
            color: theme.json.textSecondaryColor,
            border: 'none',
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
          },
          '& .cm-gutters .cm-lineNumbers .cm-gutterElement': {
            paddingLeft: spacing,
          },
          '&.cm-editor .cm-foldPlaceholder': {
            padding: '0 ' + spacing,
            backgroundColor: theme.json.backgroundColor,
            borderColor: theme.json.dividerColor,
            color: theme.json.textColor,
          },
        },
        { dark: isDarkMode }
      ),
    [isDarkMode, spacing, readOnly, theme.json, borderRadius, variant]
  )
}
