import { FC, PropsWithChildren } from 'react'
import { StyledSpacer } from './Spacer.styles'

type Props = { 
  size?: number
  mobileSize?: number
 }

export const Spacer: FC<PropsWithChildren<Props>> = (props) => {
  return <StyledSpacer 
    width={props.size} 
    height={props.size} 
    hideOnDesktop={props.size === 0}
    mobileWidth={props.mobileSize >= 0 ? props.mobileSize : props.size} 
    mobileHeight={props.mobileSize >= 0 ? props.mobileSize : props.size}
    hideOnMobile={props.mobileSize === 0}
  ></StyledSpacer>
}
