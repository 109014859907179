import styled from 'styled-components'

export const StyledMobileHeader = styled.div`
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        display: block;
        background-color: ${({ theme }) => theme.colors.quaternary};
        padding: 18px 30px;
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 1;
        width: 100%;
    }
`

export const StyledLogo = styled.div`
  width: 55px;
  height: 29px;
`