interface Params {
  [key: string]: string | number | boolean | string[]
}

export const parseQueryParams = (filter: Params): Params => {
  if (
    filter === undefined ||
    Object.prototype.hasOwnProperty.call(filter, 'undefined')
  )
    return {}

  const formattedParams: Params = {}

  const objectEntriesWithoutUndefined = Object.entries(filter).filter(
    ([key, value]) => value !== undefined && key !== undefined
  )

  for (const [key, value] of objectEntriesWithoutUndefined) {
    formattedParams[`${key}`] = value
  }

  return formattedParams
}
