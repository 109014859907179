import { observer } from 'mobx-react'
import { FC, useCallback, useContext } from 'react'
import {
  Button,
} from 'authflow-ui-engine'
import { redirect } from 'react-router-dom'
import { StoreContext } from '../../App'
import { axiosInstance } from '../../methods/axiosConfig'
import { useSearchParams } from 'react-router-dom'
import { Logo } from '../../shared/components/Logo'
import { Spacer } from '../../shared/components/Spacer'
import { Subtitle } from '../../shared/components/Subtitle'
import { Title } from '../../shared/components/Title'
import {
  Aligner,
  Container,
  LeftSide,
  PositionedIllustration,
  PositionedLogo,
  RightSide,
  TransparentContainer,
} from './ErrorPage.styles'
import { ErrorIllustration } from './components/ErrorIllustration'
import { useTheme } from 'styled-components'

export const ErrorPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { errorPage: trans } =
    store.TranslationsState.translations
  const theme = useTheme()

  const [searchParams] = useSearchParams();

  const title = searchParams.get('title');
  const description = searchParams.get('description');

  const handleLogout = useCallback( () => {
    store.Auth.setIsLogged(false)
    store.Auth.setShouldLogout(true)
    store.Auth.setCurrentUserId('')
    window.location.href = '/login'
  }, [])

  return (
    <Container>
      <LeftSide>
        <PositionedLogo>
          <Logo></Logo>
        </PositionedLogo>
        <PositionedIllustration>
          <ErrorIllustration></ErrorIllustration>
        </PositionedIllustration>
      </LeftSide>
      <RightSide>
        <Aligner>
            <TransparentContainer>
              <Title>{title || trans.somethingWentWrong}</Title>
              <Spacer size={14}></Spacer>
              <Subtitle>
                {description}
              </Subtitle>
              <Spacer size={50} mobileSize={25}></Spacer>
                <Button 
                  {...theme.buttons.commonButton} 
                  onClick={handleLogout}
                  fontSize="1.56rem"
                >
                  {trans.logOut}
                </Button>
                <Spacer size={25}></Spacer>
            </TransparentContainer>
        </Aligner>
      </RightSide>
    </Container>
  )
})
