import { FC, PropsWithChildren } from 'react'
import { StyledLogsIcon } from './LogsIcon.styles'

export const LogsIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledLogsIcon>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        fill="currentColor"
      >
        <g>
          <g>
            <path d="M23.5,4.5h-17C6.224,4.5,6,4.276,6,4s0.224-0.5,0.5-0.5h17C23.776,3.5,24,3.724,24,4S23.776,4.5,23.5,4.5z" />
          </g>
          <g>
            <path d="M23.5,12.5h-17C6.224,12.5,6,12.276,6,12s0.224-0.5,0.5-0.5h17c0.276,0,0.5,0.224,0.5,0.5S23.776,12.5,23.5,12.5z" />
          </g>
          <g>
            <path d="M23.5,20.5h-17C6.224,20.5,6,20.276,6,20s0.224-0.5,0.5-0.5h17c0.276,0,0.5,0.224,0.5,0.5S23.776,20.5,23.5,20.5z" />
          </g>
          <g>
            <path
              d="M2.5,14.5C1.121,14.5,0,13.378,0,12s1.121-2.5,2.5-2.5S5,10.622,5,12S3.879,14.5,2.5,14.5z M2.5,10.5
			C1.673,10.5,1,11.173,1,12s0.673,1.5,1.5,1.5S4,12.827,4,12S3.327,10.5,2.5,10.5z"
            />
          </g>
          <g>
            <path
              d="M2.5,22.5C1.121,22.5,0,21.378,0,20s1.121-2.5,2.5-2.5S5,18.622,5,20S3.879,22.5,2.5,22.5z M2.5,18.5
			C1.673,18.5,1,19.173,1,20s0.673,1.5,1.5,1.5S4,20.827,4,20S3.327,18.5,2.5,18.5z"
            />
          </g>
          <g>
            <path
              d="M2.5,6.5C1.121,6.5,0,5.378,0,4s1.121-2.5,2.5-2.5S5,2.622,5,4S3.879,6.5,2.5,6.5z M2.5,2.5C1.673,2.5,1,3.173,1,4
			s0.673,1.5,1.5,1.5S4,4.827,4,4S3.327,2.5,2.5,2.5z"
            />
          </g>
        </g>
      </svg>
    </StyledLogsIcon>
  )
}
