import { commonButton, commonTheme } from './common.theme'
import { Button, Colors, Json, Theme } from './theme'

const colors: Colors = {
  primary: '#41FFD1',
  primary2: '#28e2b4',
  secondary: '#2A0062',
  secondary2: '#2A0062',
  secondary3: '#2A0062',
  secondary4: '#2A0062',
  secondary5: '#2A0062',
  secondary6: '#2A0062',
  secondary7: '#491296',
  secondary8: '#491296',
  secondary9: '#2A0062',
  tertiary: '#F2F0F6',
  tertiary2: '#F2F0F6',
  tertiary3: '#F2F0F6',
  quaternary: '#FFFFFF',
  quaternary2: '#FFFFFF',
  quaternary3: '#FFFFFF',
  quaternary4: '#FFFFFF',
  quaternary5: '#FFFFFF',
  quinary: '#E5E4E9',
  senary: '#9B91B0',
  septenary: '#98959F',
  septenary2: '#98959F',
  septenary3: '#665784',
  scrollTrackColor: '#9B91B0',
  scrollHandleColor: '#2A0062',
}

const button: Button = {
  ...commonButton,
  bgColor: colors.primary,
  color: colors.secondary3,
  bgColorHover: colors.primary,
  colorHover: colors.secondary3,
  bgColorDisabled: colors.quinary,
  colorDisabled: colors.septenary,
}

const createTransactionClearButton: Button = {
  ...commonButton,
  width: '115px',
  height: '40px',
  fontSize: '16px',
  color: colors.secondary,
  bgColor: 'transparent',
  border: `1px solid transparent`,
  colorHover: colors.secondary,
  borderHover: `1px solid ${colors.secondary}`,
  bgColorHover: 'transparent',
  textDecoration: 'underline',
}

const createTransactionCreateButton: Button = {
  ...commonButton,
  width: '115px',
  height: '40px',
  fontSize: '16px',
  color: colors.tertiary2,
  bgColor: colors.secondary,
  border: colors.secondary,
  colorHover: colors.tertiary2,
  borderHover: colors.secondary,
  bgColorHover: colors.secondary7,
}

const createTransactionTabButton: Button = {
  ...commonButton,
  width: 'auto',
  height: 'auto',
  padding: '12px 25px',
  borderRadius: '16px 16px 0 0',
  bgColor: 'transparent',
  color: colors.secondary,
  bgColorHover: colors.secondary,
  colorHover: colors.quaternary,
}

const createTransactionTabButtonActive: Button = {
  ...commonButton,
  width: 'auto',
  height: 'auto',
  padding: '12px 25px',
  borderRadius: '16px 16px 0 0',
  bgColor: colors.tertiary3,
  color: colors.secondary,
  bgColorHover: colors.tertiary3,
  colorHover: colors.secondary,
}

const copyPayloadButton: Button = {
  ...commonButton,
  width: '215px',
  widthMobile: '215px',
  height: '29px',
  heightMobile: '29px',
  bgColor: 'transparent',
  border: `1px solid ${colors.septenary3}`,
  color: colors.septenary3,
  fontSize: '12px',
  bgColorHover: colors.septenary3,
  colorHover: colors.tertiary3,
}

const json: Json = {
  textColor: '#2A0760',
  textSecondaryColor: '#2A0760',
  headlineColor: '#2A0760',
  backgroundColor: '#F2EDFE',
  lineHeight: '22px',
  linePadding: '0px 3px 0px 4px',
  borderDefault: 'rgba(255, 255, 255, 0.23)',
  borderFocused: '#05aeca',
  borderInvalid: '#9d190f',
  selectionMatch: '#ae38a782',
  activeLineGutter: '#7D18FF11',
  activeLine: '#7D18FF11',
  activeSelection: '#1872b9',
  dividerColor: '#EEE',
  spacing: '4px',
  contentPadding: `calc(4px / 2)`,
  borderRadius: '8px',
}

export const lightTheme: Theme = {
  ...commonTheme,
  colors: {
    ...colors,
  },
  calendar: {
    borderRadius: '5px',
    borderColor: 'rgb(229, 228, 233)',
    backgroundColor: '#FFFFFF',
    backgroundColorDisabled: '#f0f0f0',
    dayColor: '#000',
    tile: {
      borderRadius: '100px',
      padding: '10px 6.6667px',
      hover: {
        backgroundColor: 'rgba(65, 255, 209, 0.5)',
      },
      disabled: {
        backgroundColor: '#f0f0f0',
      },
      now: {
        backgroundColor: 'rgb(42, 0, 98)',
        color: 'white',
        hover: {
          backgroundColor: 'rgba(42, 0, 98, 0.7)',
        },
      },
      active: {
        backgroundColor: 'rgb(65, 255, 209)',
        color: 'rgb(42, 0, 98)',
        hover: {
          backgroundColor: 'rgba(65, 255, 209, 0.7)',
        },
      },
      hasActive: {
        backgroundColor: 'rgba(65, 255, 209)',
        color: 'rgb(42, 0, 98)',
        hover: {
          backgroundColor: 'rgba(65, 255, 209, 0.5)',
        },
      },
    },
    navigation: {
      button: {
        borderRadius: '100px',
        hover: {
          backgroundColor: 'rgba(65, 255, 209, 0.5)',
        },
        disabled: {
          backgroundColor: '#f0f0f0',
        },
      },
    },
  },
  createTransaction: {
    mainBgColor: '#FFFFFF',
    mainBorderColor: '#cbc6d3',
    mainColor: '#cbc6d3',
    mainHeaderColor: '#2A0062',
    objectEditorBgColor: '#f2edfe',
    tabActiveBgColor: '#F2F0F6',
    tabActiveColor: '#2a0062',
    tabBgColor: 'transparent',
    tabColor: '#9b91b0',
    tabContentColor: '#2a0062',
    resultsBgColor: '#eae7f1',
    resultsActiveBgColor: '#FFFFFF',
    resultsValueColor: '#2a0062',
    resultsPropertyColor: '#665784',
    resultsLinkColor: '#433D49',
    resultsSuccessStatusColor: '#60A340',
    resultsErrorStatusColor: '#D63649',
    resultsOpenButtonBgColor: '#2A0062',
    resultsOpenButtonColor: '#F2F0F6',
    resultsOpenButtonBorderColor: '#2A0062',
    resultsSliderTrackColor: '#CBC6D3',
    resultsSliderHandleColor: '#2A0062',
    clearButton: createTransactionClearButton,
    createButton: createTransactionCreateButton,
    tabButton: createTransactionTabButton,
    tabButtonActive: createTransactionTabButtonActive,
    copyPayloadButton: copyPayloadButton,
  },
  loadingSpinner: {
    primary: '#41FFD1',
    bgColor: '#F2F0F6',
    bgIframe: '#FFFFFF',
  },
  sidebar: {
    boxShadow: '0px 0px 10px 0px #CBC6D3',
  },
  buttons: {
    commonButton: {
      ...button,
    },
    closeModalButton: {
      ...button,
      width: '32px',
      height: '32px',
      bgColor: colors.quaternary5,
      bgColorHover: colors.quaternary5,
      padding: '0',
    },
    clearFiltersButton: {
      ...button,
      height: '37px',
      margin: '21px 0 0',
      padding: '0 20px',
      bgColor: colors.primary,
      color: colors.secondary3,
      border: 'none',
      widthMobile: '100%',
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.primary2,
    },
    groupingDataButton: {
      ...button,
      color: colors.secondary2,
      bgColor: 'inherit',
      padding: '0',
      bgColorHover: 'inherit',
    },
    loadMoreButton: {
      ...button,
      width: 'auto',
      height: 'auto',
      padding: '10px 20px',
      border: `1px solid ${colors.quinary}`,
      bgColor: colors.quaternary,
      color: colors.secondary2,
      borderHover: `1px solid ${colors.quinary}`,
      bgColorHover: colors.quinary,
      colorHover: colors.secondary2,
      bgColorDisabled: 'transparent',
    },
    goBackButton: {
      ...button,
      height: '24px',
      heightMobile: '24px',
      width: 'auto',
      widthMobile: 'auto',
      fontSize: '11px',
      padding: '0 20px',
      color: colors.secondary2,
      bgColor: 'transparent',
      border: `1px solid ${colors.septenary}`,
      bgColorHover: colors.septenary,
      colorHover: colors.secondary5,
      borderHover: `1px solid ${colors.septenary}`,
      margin: '7px 15px 0 0',
    },
    paginationButton: {
      ...button,
      width: 'auto',
      widthMobile: 'auto',
      height: 'auto',
      padding: '10px 20px',
      borderRadius: '24px',
      border: `1px solid ${colors.quinary}`,
      bgColor: colors.quaternary,
      color: colors.secondary2,
      borderHover: `1px solid ${colors.quinary}`,
      bgColorHover: colors.quinary,
      colorHover: colors.secondary2,
      bgColorDisabled: 'transparent',
    },
    downloadButton: {
      ...button,
      bgColor: colors.primary,
      width: 'auto',
      widthMobile: 'auto',
      height: 'auto',
      margin: '10px 0 0',
      padding: '9px 10px',
      color: colors.secondary3,
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.primary2,
      borderRadiusHover: '0',
    },
    popupPrimaryButton: {
      ...button,
      bgColor: colors.primary,
      width: 'fit-content',
      widthMobile: 'fit-content',
      height: 'auto',
      margin: '0',
      padding: '9px 10px',
      color: colors.secondary3,
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.primary2,
      borderRadiusHover: '0',
    },
    popupDeclineButton: {
      ...button,
      bgColor: colors.quaternary,
      width: 'fit-content',
      widthMobile: 'fit-content',
      height: 'auto',
      margin: '0',
      padding: '9px 10px',
      color: colors.secondary3,
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.septenary2,
      borderRadiusHover: '0',
    },
  },
  textInput: {
    height: '35px',
    color: colors.secondary2,
    border: `1px solid ${colors.quinary}`,
    bgColor: 'inherit',
    padding: '6px 6px 6px 12px',
    borderRadius: '5px',
    colorFocus: colors.secondary2,
    bgColorFocus: 'inherit',
    borderDisabled: `1px solid ${colors.quinary}`,
    bgColorDisabled: colors.quinary,
    colorDisabled: colors.septenary2,
  },
  popup: {
    wrapBgColor: 'rgba(12, 1, 27, 0.59)',
    color: colors.secondary2,
    bgColor: colors.quaternary,
    border: `2px solid ${colors.tertiary2}`,
    borderRadius: '16px',
    mobileWidth: '',
    mobileMargin: '',
    mobilePadding: '',
    headerColor: colors.secondary4,
    headerBgColor: colors.quaternary5,
    closeTop: '20px',
    closeRight: '30px',
  },
  selectInput: {
    borderRadius: '5px',
    borderWidth: '1px',
    color: colors.secondary2,
    borderColor: colors.quinary,
    colorDisabled: colors.septenary2,
    backgroundColor: colors.quaternary,
    borderColorFocus: colors.secondary2,
    colorFocus: colors.quaternary,
    backgroundColorFocus: colors.primary2,
    colorSelected: colors.quaternary,
    backgroundColorSelected: colors.secondary,
    menuMarginTop: '1px',
    menuBorderWidth: '1px',
    danger: 'rgba(226,64,40,1)',
    dangerLight: 'rgba(226,64,40,0.5)',
  },
  json: json,
}
