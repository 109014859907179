import { FC, PropsWithChildren } from 'react'
import { StyledGoBackIcon } from './GoBackIcon.styles'

export const GoBackIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledGoBackIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17.885 3.77L16.115 2L6.11499 12L16.115 22L17.885 20.23L9.65499 12L17.885 3.77Z" fill="currentColor" />
      </svg>
    </StyledGoBackIcon>
  )
}
