import { FC, PropsWithChildren } from 'react'
import { TagPillStyled } from './TagPill.styles'
import { svgs } from './icons/TagPillIcons'

type Props = {
  tagsSettings: string
  tag: string
}

export const TagPill: FC<PropsWithChildren<Props>> = ({ tagsSettings, tag }: Props) => {
  return (
    <TagPillStyled
      withBackground={tagsSettings === 'tags' || tagsSettings === 'both' || (tagsSettings === 'icons' && !svgs[tag])}
    >
      {(tagsSettings === 'icons' || tagsSettings === 'both') && svgs[tag]}
      {(tagsSettings === 'tags' || tagsSettings === 'both' || (tagsSettings === 'icons' && !svgs[tag])) && (
        <span>{tag}</span>
      )}
    </TagPillStyled>
  )
}
