import styled, { css } from 'styled-components'
import { MenuButton } from '@szhsin/react-menu'
import { Animatable } from '../../../shared/themes/common.styles'

export const StyledTagFilterDropdown = styled.div`
  position: relative;

  .szh-menu-container {
    width: 100%;
    z-index: 99999;

    ul {
      top: 1px !important;
      background-color: ${({ theme }) => theme.colors.quaternary};
      margin-top: 0px;
      border-radius: 0px 0px 5px 5px;
      border: 1px solid ${({ theme }) => theme.colors.quinary};
      min-width: 100%;
    }
  }

  .szh-menu-button--open {
    border-radius: 5px 5px 0px 0px;
  }

  ${Animatable}
`

export const TagRow = styled.div`
  min-width: 180px;
  max-width: 230px;
  background-color: ${({ theme }) => theme.colors.quaternary};
  padding: 10px 16px;
  color: ${({ theme }) => theme.colors.secondary2};

  display: flex;
  justify-content: space-between;
  gap: 10px;

  svg {
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.colors.secondary3};
    width: 16px;
    height: 16px;
  }

  &:last-child {
    border-radius: 0px 0px 5px 5px;
  }
`

export const TagIcon = styled.div`
  width: 22px;

  svg {
    border: 0;
    width: 16px;
    height: 16px;
  }
`

export const TagName = styled.div`
  cursor: pointer;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    opacity: 0.6;
  }
`

export const OpenButton = styled(MenuButton)`
  width: 100%;
  min-width: 114px;
  height: 35px;
  font-family: Arial, sans-serif;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.quinary};
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: inherit;
  appearance: none;
  text-align: left;
  padding-left: 10px;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.secondary2}!important;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.quinary};
    background-color: ${({ theme }) => theme.colors.quinary};
    color: ${({ theme }) => theme.colors.septenary2};
    &:hover,
    &:focus {
      cursor: auto;
      border-color: ${({ theme }) => theme.colors.quinary}!important;
    }
  }
`
