import styled from 'styled-components'

export const ContextMenuContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  border: none;
  width: 100%;
  max-width: 200px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);

  &.active {
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  button {
    width: 100%;
    color: ${({ theme }) => theme.colors.secondary2};
    background-color: transparent;

    :hover {
      color: ${({ theme }) => theme.colors.secondary2};
      background-color: transparent;
    }
  }
`
