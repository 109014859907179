import styled, { css } from 'styled-components'
import { Tab } from '../TabbedContent.styles'
import { Animatable } from '../../../shared/themes/common.styles'
import { InputLabelWrapper } from '../CreateTransactionPage.styles'

export const Wrap = styled.div`
  width: 100%;
`

export const MainContainer = styled.div`
  /* border: 1px solid ${({ theme }) => theme.createTransaction.mainBorderColor}; */
  background-color: ${({ theme }) => theme.createTransaction.mainBgColor};
  border-radius: 17px;
  box-sizing: border-box;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: column;
  }
`

export const NewTransaction = styled.div`
  width: 60%;
  background-color: ${({ theme }) => theme.createTransaction.mainBgColor};
  /* border-right: 1px solid ${({ theme }) => theme.createTransaction.mainBorderColor}; */

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.createTransaction.mainBorderColor};
  }
`

export const Heading = styled.h2`
  margin: 0;
  padding: 10px 11px 10px 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.createTransaction.mainHeaderColor};
  border-bottom: 1px solid ${({ theme }) => theme.createTransaction.mainBorderColor};
`

export const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 30px 40px;
`

export const Label = styled.span`
  display: inline-flex;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.createTransaction.mainColor};

  span {
    margin-right: 5px;
  }
`

export const InfoContainer = styled.div<{ status?: 'success' | 'failure' }>`
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 14px;
  color: ${({ status }) =>
    status === 'success'
      ? ({ theme }) => theme.createTransaction.resultsSuccessStatusColor
      : ({ theme }) => theme.createTransaction.resultsErrorStatusColor};
`

export const FakeFileInput = styled.div<{ cursor: 'pointer' | 'not-allowed' }>`
  width: 100%;
  min-width: 114px;
  height: 35px;
  padding-left: 12px;
  border-radius: 5px;
  border: 1px solid rgb(229, 228, 233);
  background-color: inherit;
  color: ${({ theme }) => theme.colors.secondary2};
  transition: all 0.5s ease-out 0s;
  display: flex;
  align-items: center;
  cursor: ${({ cursor }) => cursor};

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary2}!important;
  }
`

export const PDFTextArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  /* min-height: 150px; */
  padding: 9px;
  border-radius: 5px;
  border: 1px solid rgb(229, 228, 233);
  background-color: inherit;
  color: ${({ theme }) => theme.colors.secondary2};
  transition: all 0.5s ease-out 0s;
  cursor: text;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary2}!important;
  }
`

export const FormButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`

export const Section = styled.div`
  width: 100%;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  #a_unique_id-warning-box {
    display: none !important;
  }

  #a_unique_id {
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.createTransaction.resultsSliderTrackColor};
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${({ theme }) => theme.createTransaction.resultsSliderHandleColor};
    }
  }
`

export const CreateTransactionTab = styled(Tab)`
  background-color: ${({ theme }) => theme.createTransaction.tabBgColor};

  &.active {
    color: ${({ theme }) => theme.createTransaction.tabActiveColor};
    background-color: ${({ theme }) => theme.createTransaction.tabActiveBgColor};
  }
`

export const Results = styled.div`
  width: 40%;
  background-color: ${({ theme }) => theme.createTransaction.resultsBgColor};
  border-radius: 0 17px 17px 0;
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 100%;
    border-radius: 0 0 17px 17px;
  }
`

export const ResultListWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 800px;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.createTransaction.resultsSliderTrackColor};
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ${({ theme }) => theme.createTransaction.resultsSliderHandleColor};
  }
`

export const ResultItem = styled.div<{ expand?: boolean; active?: boolean }>`
  padding: 20px 20px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.createTransaction.mainBorderColor};
  background-color: 'inherit';
  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.createTransaction.resultsActiveBgColor};
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.createTransaction.resultsActiveBgColor};
    `}
`

export const ResultStatus = styled.div<{ status?: 'success' | 'failure' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > h2 {
    color: ${({ theme }) => theme.createTransaction.resultsValueColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  & > span {
    color: ${({ status }) =>
      status === 'success'
        ? ({ theme }) => theme.createTransaction.resultsSuccessStatusColor
        : ({ theme }) => theme.createTransaction.resultsErrorStatusColor};
    font-size: 14px;
    font-weight: 700;
  }
`

export const ResultApiUrl = styled.a`
  color: ${({ theme }) => theme.createTransaction.resultsLinkColor};
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 20px;
`

export const PropertyName = styled.span<{ expand?: boolean }>`
  color: ${({ theme }) => theme.createTransaction.resultsPropertyColor};
  display: ${({ expand }) => (expand ? 'block' : 'none')};
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 4px;
`

export const PropertyValue = styled.span<{ expand?: boolean }>`
  color: ${({ theme }) => theme.createTransaction.resultsValueColor};
  display: ${({ expand }) => (expand ? 'block' : 'none')};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;

  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.createTransaction.resultsValueColor};
    display: ${({ expand }) => (expand ? 'block' : 'none')};
    font-size: 14px;
    font-weight: 600;
  }
`

export const ResultButtonRow = styled.div<{ expand?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: ${({ expand }) => (expand ? '14px' : '4px')};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    cursor: pointer;
    border-radius: 20px;
  }

  & > a {
    width: 177px;
    height: 29px;
    background: ${({ theme }) => theme.createTransaction.resultsOpenButtonBgColor};
    border: 1px solid #${({ theme }) => theme.createTransaction.resultsOpenButtonBorderColor};
    color: ${({ theme }) => theme.createTransaction.resultsOpenButtonColor};
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;

    :hover {
      background-color: ${({ theme }) => theme.colors.secondary7};
    }
    ${Animatable}
  }
`

export const TenantSwitcherWrapper = styled(InputLabelWrapper)`
  margin-bottom: 20px;
  max-width: 250px;

  label {
    color: ${({ theme }) => theme.createTransaction.mainColor};
    font-size: 14px;
  }

  //until dark mode ready
  .select-input__single-value {
    color: ${({ theme }) => theme.createTransaction.resultsValueColor} !important;
  }
`

export const LoaderWrapper = styled.div`
  min-height: 286px;
  border-bottom: 1px solid ${({ theme }) => theme.createTransaction.mainBorderColor};
`

export const ResultErrorMessage = styled.div`
  color: ${({ theme }) => theme.createTransaction.resultsErrorStatusColor};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    width: 30%;
  }

  & > input,
  & > div {
    width: 100%;
  }

  span {
    padding-bottom: 0%;
    font-size: 14px;
  }

  input {
    transition: all 0.3ms ease-in-out;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`
