import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { Link, StyledMenuItem } from './MenuItem.styles'

type Props = {
  link?: string
  active?: boolean
  onClick?: MouseEventHandler
}

export const MenuItem: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledMenuItem active={props.active} onClick={props.onClick}>
      <Link href={props.link}>{props.children}</Link>
    </StyledMenuItem>
  )
}
