import { FC, useEffect, useMemo, useRef, useState, useContext } from 'react'
import { StoreContext } from '../../../App'
import { TransactionHistoryItem } from '../SingleTransactionPage.interface'

import {
  StyledTable,
  TableContainer,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableRowCell,
  Resizer,
  CellTextSelectionWrapper,
} from './Table.styles'

import { useReactTable, getCoreRowModel, ColumnDef, flexRender } from '@tanstack/react-table'
import { capitalizeEachWord } from '../../../shared/utils/handleTexts'
import { getFormatedDate } from '../../../shared/utils/handleDate'

type Props = {
  steps: TransactionHistoryItem[]
}

const ROW_NO_WIDTH = 60

export const TransactionHistoryTable: FC<Props> = ({ steps }) => {
  const store = useContext(StoreContext)
  const { language, translations } = store.TranslationsState
  const { singleTransactionHistory: trans } = translations
  const ref = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState<number>()

  const resizeHandler = () => {
    const fullWidth = window.innerWidth <= 820 ? 1000 : ref.current.clientWidth - ROW_NO_WIDTH - 10
    setWidth(fullWidth / 2)
  }

  useEffect(() => {
    resizeHandler()
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const defaultColumns: ColumnDef<TransactionHistoryItem>[] = useMemo(
    () => [
      {
        accessorKey: 'step',
        accessorFn: (row) => capitalizeEachWord(row.step.replace(/_/g, ' ')),
        cell: (info) => <div>{String(info.getValue())}</div>,
        header: trans.step,
        size: width,
      },
      {
        accessorKey: 'timestamp',
        accessorFn: (row) => getFormatedDate(Math.floor(new Date(row.timestamp).getTime() / 1000)),
        cell: (info) => <div>{String(info.getValue())}</div>,
        header: trans.timestamp,
        size: width,
      },
    ],
    [width, language]
  )

  useEffect(() => {
    setColumns(() => [...defaultColumns])
  }, [defaultColumns])

  const [data, setData] = useState(() => [...steps])
  const [columns, setColumns] = useState<typeof defaultColumns>(() => [...defaultColumns])

  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  })

  return (
    <StyledTable className="overflow-x-auto" ref={ref}>
      <TableContainer style={{ width: table.getTotalSize() }}>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeader key={headerGroup.id}>
            <TableHeaderCell key="row_no" style={{ width: `${ROW_NO_WIDTH}px` }}></TableHeaderCell>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell key={header.id} style={{ width: header.getSize() }}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                <Resizer
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                />
              </TableHeaderCell>
            ))}
          </TableHeader>
        ))}

        {table.getRowModel().rows.map((row, index) => {
          return (
            <TableRow key={row.original.timestamp} notClickable>
              <TableRowCell key="row_no" style={{ width: `${ROW_NO_WIDTH}px` }}>
                {index + 1}
              </TableRowCell>
              {row.getVisibleCells().map((cell) => (
                <TableRowCell key={cell.id} style={{ width: cell.column.getSize() }}>
                  <CellTextSelectionWrapper>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </CellTextSelectionWrapper>
                </TableRowCell>
              ))}
            </TableRow>
          )
        })}
      </TableContainer>
    </StyledTable>
  )
}
