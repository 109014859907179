import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`

export const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.tertiary2};
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    height: 50vh;
    overflow: hidden;
  }
`

export const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.quaternary};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    height: 50vh;
  }
`

export const PositionedLogo = styled.div`
  width: 113px;
  height: 62px;
  position: absolute;
  left: 95px;
  top: 85px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 61px;
    height: 34px;
    left: 50px;
    top: 50px;
  }
`

export const PositionedIllustration = styled.div`
  width: 100%;
  margin: 0 130px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0 90px;
  }
`

export const Aligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    justify-content: unset;
    min-height: 80%;
  }
`

export const TransparentContainer = styled.div`
  width: 484px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 80%;
  }
`

export const WarningMessage = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
  border: 1px solid #d3ae00;
  background-color: #ffe778;
  text-align: center;
  margin-bottom: 20px;
`
