import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { CreatableSelectInput, SelectInput, Button, LoadingSpinner, TextInput } from 'authflow-ui-engine'
import { StoreContext } from '../../../App'
import { SelectOption } from '../../../constants/SelectOption'
import { Spacer } from '../../../shared/components/Spacer'
import { DropdownWrapper, FilterRow, FixedFilter } from '../../logs/TransactionsPage.styles'
import { InputLabelWrapper } from '../CreateTransactionPage.styles'
import {
  Content,
  CreateTransactionTab,
  FakeFileInput,
  FormButtonRow,
  Heading,
  InfoContainer,
  InputContainer,
  Label,
  LoaderWrapper,
  MainContainer,
  NewTransaction,
  PDFTextArea,
  ResultListWrapper,
  Results,
  Section,
  Wrap,
} from './RequestForm.styles'

import { Nav, TabbedContent } from '../TabbedContent.styles'
import {
  DEFAULT_REDIRECT_URL,
  CONFIGURATION_LIST,
  CONFIGURATION_LIST_V3,
  LANGUAGES_LIST,
  PDF_NAME_LIST,
  THEME_LIST,
  isValidUrl,
  JsonError,
  TransactionPostRequest,
  TransactionResponseParsed,
  convertToBase64,
  pdfSelector,
  postTransaction,
  V3_OPTIONS_LIST,
} from './CreateTransationMethods'
import { useTheme } from 'styled-components'
import { Result } from './Result'
import { toast } from 'react-toastify'
import { CheckIcon } from './CheckIcon'
import { ErrorIcon } from './ErrorIcon'
import { useLocalStorage } from 'usehooks-ts'
import { ModularizationIcon } from '../../logs/components/icons/ModularizationIcon'
import { JSONEditor } from '../../../shared/components/JSONEditor/JSONEditor'

type Tabs = 'mock' | 'upload' | 'base64'

const initObj: TransactionPostRequest = {
  configurationId: '<configurationId>',
  preferredLanguage: '<preferredLanguage>',
  redirectUrl: '<redirectUrl>',
}

const initObjV3 = (configurationId: string): TransactionPostRequest => {
  if (configurationId === 'demo.addressVerification') {
    return {
      configurationId: '<configurationId>',
      preferredLanguage: '<preferredLanguage>',
      redirectUrl: '<redirectUrl>',
      user: {
        email: 'someone@signicat.com',
        firstName: 'Erika',
        lastName: 'Mustermann',
        address: {
          city: 'Köln',
          postalCode: '51147',
          street: 'Heidestrasse 17',
        },
      },
    }
  } else {
    return {
      configurationId: '<configurationId>',
      preferredLanguage: '<preferredLanguage>',
      redirectUrl: '<redirectUrl>',
      user: {
        email: 'someone@signicat.com',
        firstName: 'Erika',
        lastName: 'Mustermann',
        address: {
          city: 'Köln',
          postalCode: '51147',
          street: 'Heidestrasse 17',
        },
        dateOfBirth: '1980-01-01',
        nationalities: ['POL', 'DEU'],
        bankInformation: {
          accountHolder: 'Erika Mustermann',
          iban: 'DE62888888880012345678',
        },
      },
    }
  }
}

export const RequestForm: FC<PropsWithChildren> = observer(() => {
  const [pageLoaded, setPageLoaded] = useState(false)

  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const theme = useTheme()

  const { tenantsWithWriteRights, selectedTenant, tenants } = store.AppState
  const { themeIds, configIds } = store.Filters

  const inputFile = useRef<HTMLInputElement>()

  const [configurationIDInputValue, setConfigurationIDInputValue] = useState<{
    label: string
    value: string
  }>(null)
  const [preferredLanguageInputValue, setPreferredLanguageInputValue] = useState<{
    label: string
    value: string
  }>(LANGUAGES_LIST[0])
  const [themeIdInputValue, setThemeIdInputValue] = useState<{
    label: string
    value: string
  }>(null)
  const [configIdsList, setConfigIdsList] = useState<
    {
      label: string
      value: string
    }[]
  >(null)
  const [themeIdsList, setThemeIdsList] = useState<
    {
      label: string
      value: string
    }[]
  >(null)
  const [redirectUrlInputValue, setRedirectUrlInputValue] = useState<string>(DEFAULT_REDIRECT_URL)
  const [phoneNumberInputValue, setPhoneNumberInputValue] = useLocalStorage<string>('phoneNumberInputValue', '')
  const [emailInputValue, setEmailInputValue] = useLocalStorage<string>('emailInputValue', '')
  const [v3InputValue, setV3InputValue] = useState<{
    label: string
    value: string
  }>(V3_OPTIONS_LIST[0])

  const [validationErrorConfigurationID, setValidationErrorConfigurationID] = useState(false)
  const [validationErrorRedirectUrl, setValidationErrorRedirectUrl] = useState(false)
  const [validationErrorEmail, setValidationErrorEmail] = useState(false)
  const [validationErrorPhone, setValidationErrorPhone] = useState(false)

  const [currentObjError, setCurrentObjError] = useState<JsonError | null>(null)
  const [currentObjErrorValue, setCurrentObjErrorValue] = useState<boolean>(false)

  const [currentObj, setCurrentObj] = useState<TransactionPostRequest>(initObj)

  const [selectedMockPdf, setSelectedMockPdf] = useState<{
    label: string
    value: string
  }>(PDF_NAME_LIST[0])

  const [selectedFilePdf, setSelectedFilePdf] = useState<File>()

  const [selectedBase64Pdf, setSelectedBase64Pdf] = useState<string>('')

  const [responseList, setResponseList] = useState<TransactionResponseParsed[]>([])

  const [fetching, setFetching] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const isWaitingForTenants = !tenantsWithWriteRights?.length || !selectedTenant?.length
  const isWriteAllowed = tenantsWithWriteRights.includes(selectedTenant)

  const [activeTab, setActiveTab] = useState<Tabs>('mock')

  const handlePdfFileInputChange = () => {
    setSelectedFilePdf(inputFile.current.files[0])
  }

  const handlePdfFileSelection = useCallback(() => {
    if (!fetching && !isWaitingForTenants) {
      inputFile.current.click()
    }
  }, [fetching, isWaitingForTenants])

  useEffect(() => {
    store.AppState.setSelectedWriteTenant(tenantsWithWriteRights[0])
  }, [tenantsWithWriteRights])

  const renderResults = useCallback(
    () =>
      responseList.map((response, index) => (
        <Result response={response} index={index} fetching={fetching} key={index} />
      )),
    [responseList, fetching]
  )

  const handleSubmit = useCallback(async () => {
    let selectedPDFForRequest = ''

    switch (activeTab) {
      case 'mock':
        selectedPDFForRequest = pdfSelector(selectedMockPdf.value)
        break
      case 'upload':
        selectedPDFForRequest = (await convertToBase64(selectedFilePdf)).split(',')[1]
        break
      case 'base64':
        selectedPDFForRequest = selectedBase64Pdf
        break
    }

    setFetching(true)

    try {
      const submitRes = await postTransaction(
        selectedTenant,
        currentObj,
        selectedPDFForRequest,
        v3InputValue.value === 'on'
      )

      setResponseList([submitRes, ...responseList])
    } catch (error) {
      setResponseList([
        {
          transactionId: '-',
          proceedToken: '-',
          proceedUrl: '-',
          status: 'Error',
          date: '-',
          apiUrl: '-',
        },
        ...responseList,
      ])
    }

    setFetching(false)
  }, [selectedTenant, currentObj, selectedMockPdf, selectedFilePdf, selectedBase64Pdf, activeTab, responseList])

  useEffect(
    () =>
      setDisabled(
        fetching ||
          isWaitingForTenants ||
          !isWriteAllowed ||
          !configurationIDInputValue ||
          validationErrorConfigurationID ||
          !redirectUrlInputValue ||
          validationErrorRedirectUrl
      ),
    [
      fetching,
      isWaitingForTenants,
      isWriteAllowed,
      configurationIDInputValue,
      validationErrorConfigurationID,
      redirectUrlInputValue,
      validationErrorRedirectUrl,
    ]
  )

  useEffect(() => {
    if (!isWriteAllowed && pageLoaded) {
      //eslint-disable-next-line @typescript-eslint/no-unsafe-call
      toast(trans.noWriteRights, { type: 'warning' })
    }
  }, [disabled, isWriteAllowed, selectedTenant, pageLoaded])

  useEffect(() => {
    if (tenants?.length) {
      setPageLoaded(true)
    }
  }, [tenants])

  useEffect(() => {
    const tempInitObj = v3InputValue.value === 'on' ? initObjV3(configurationIDInputValue.value) : initObj

    setCurrentObj({
      ...tempInitObj,
      configurationId: configurationIDInputValue?.value || '<configurationId>',
      preferredLanguage: preferredLanguageInputValue.value || '<preferredLanguage>',
      themeId: themeIdInputValue?.value || '<themeId>',
      redirectUrl: redirectUrlInputValue && !validationErrorRedirectUrl ? redirectUrlInputValue : '<redirectUrl>',
      user:
        (phoneNumberInputValue && !validationErrorPhone) ||
        (emailInputValue && !validationErrorEmail) ||
        (tempInitObj.user &&
          Object.keys(tempInitObj.user).filter((key) => key !== 'phoneNumber' && key !== 'email').length > 0)
          ? {
              ...tempInitObj.user,
              phoneNumber:
                phoneNumberInputValue && !validationErrorPhone ? phoneNumberInputValue : tempInitObj?.user?.phoneNumber,
              email: emailInputValue && !validationErrorEmail ? emailInputValue : tempInitObj?.user?.email,
            }
          : undefined,
    })
  }, [
    configurationIDInputValue,
    emailInputValue,
    phoneNumberInputValue,
    preferredLanguageInputValue,
    themeIdInputValue,
    redirectUrlInputValue,
    v3InputValue,
  ])

  useEffect(() => {
    const tempConfigIds = configIds.length
      ? configIds
      : v3InputValue.value === 'on'
      ? CONFIGURATION_LIST_V3
      : CONFIGURATION_LIST
    const tempThemeIds = themeIds.length ? themeIds : THEME_LIST

    setConfigIdsList(tempConfigIds)
    setConfigurationIDInputValue(tempConfigIds[0])

    setThemeIdsList(tempThemeIds)
    setThemeIdInputValue(tempThemeIds[0])
  }, [themeIds, v3InputValue])

  return (
    <Wrap>
      <MainContainer>
        <NewTransaction>
          <Heading>{trans.newTransaction}</Heading>
          <Content>
            <Section>
              <Label>{trans.editConfiguration}:</Label>
              <InputContainer>
                <Label>{trans.configurationID} *</Label>
                <DropdownWrapper>
                  <CreatableSelectInput
                    options={configIdsList}
                    value={configurationIDInputValue}
                    onChange={(e: SelectOption) => setConfigurationIDInputValue(e)}
                    onError={(e: boolean) => setValidationErrorConfigurationID(e)}
                    {...theme.selectInput}
                  />
                </DropdownWrapper>
              </InputContainer>

              <Spacer size={10} />

              <InputContainer>
                <Label>{trans.preferredLanguage}</Label>
                <DropdownWrapper>
                  <SelectInput
                    options={LANGUAGES_LIST}
                    value={preferredLanguageInputValue}
                    onChange={(e: SelectOption) => setPreferredLanguageInputValue(e)}
                    {...theme.selectInput}
                  />
                </DropdownWrapper>
              </InputContainer>

              <Spacer size={10} />

              <InputContainer>
                <Label>{trans.themeId}</Label>
                <DropdownWrapper>
                  <CreatableSelectInput
                    options={themeIdsList}
                    value={themeIdInputValue}
                    onChange={(e: SelectOption) => setThemeIdInputValue(e)}
                    {...theme.selectInput}
                  />
                </DropdownWrapper>
                {/* <TextInput
                  type="text"
                  value={themeIdInputValue?.value}
                  onChange={(value: string) => {
                    setThemeIdInputValue({ label: value, value })
                  }}
                  //eslint-disable-next-line @typescript-eslint/no-empty-function
                  onError={() => {}}
                  {...theme.textInput}
                /> */}
              </InputContainer>

              <Spacer size={10} />

              <InputContainer>
                <Label>{trans.redirectURL} *</Label>
                <TextInput
                  type="text"
                  required={true}
                  value={redirectUrlInputValue}
                  onChange={(value: string) => {
                    setRedirectUrlInputValue(isValidUrl(value) ? value : undefined)
                  }}
                  onError={(e: boolean) => setValidationErrorRedirectUrl(e)}
                  customErrorLabel={
                    !isValidUrl(redirectUrlInputValue)
                      ? 'Please give a valid url including http:// or https:// protocol'
                      : undefined
                  }
                  {...theme.textInput}
                />
              </InputContainer>

              <Spacer size={10} />

              <InputContainer>
                <Label>{trans.phoneNumber}</Label>
                <TextInput
                  type="phone"
                  value={phoneNumberInputValue}
                  onChange={(value: string) => {
                    setPhoneNumberInputValue(value)
                  }}
                  onError={(e: boolean) => setValidationErrorPhone(e)}
                  {...theme.textInput}
                />
              </InputContainer>

              <Spacer size={10} />

              <InputContainer>
                <Label>{trans.email}</Label>
                <TextInput
                  type="email"
                  value={emailInputValue}
                  onChange={(value: string) => {
                    setEmailInputValue(value)
                  }}
                  onError={(e: boolean) => setValidationErrorEmail(e)}
                  {...theme.textInput}
                />
              </InputContainer>

              <Spacer size={10} />

              <InputContainer>
                <Label>
                  <ModularizationIcon size={16} /> {trans.modularization}
                </Label>
                <DropdownWrapper>
                  <CreatableSelectInput
                    options={V3_OPTIONS_LIST}
                    value={v3InputValue}
                    {...theme.selectInput}
                    onChange={(e: SelectOption) => setV3InputValue(e)}
                  />
                </DropdownWrapper>
              </InputContainer>

              <InfoContainer status={currentObjError?.length ? 'failure' : 'success'}>
                {currentObjError?.length ? <ErrorIcon /> : <CheckIcon />}
                {currentObjError?.length ? trans.errorinLine : trans.jsonValidated}
              </InfoContainer>

              <JSONEditor
                // key={dynamicKeyForThemeSwitch}
                value={JSON.stringify(currentObj, null, 2)}
                onChange={(e, val) => {
                  if (!val) return
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  setCurrentObj(JSON.parse(val))
                }}
                onLint={(diagnostics: JsonError) => {
                  setCurrentObjErrorValue(!!diagnostics?.length)
                  setCurrentObjError(diagnostics)
                }}
                maxHeight="360px"
                height="100%"
              />
            </Section>
            <Section>
              <Label>{trans.PDFdocument}</Label>
              <TabbedContent>
                <Nav>
                  <Button
                    onClick={() => {
                      setActiveTab('mock')
                    }}
                    {...(activeTab === 'mock'
                      ? theme.createTransaction.tabButtonActive
                      : theme.createTransaction.tabButton)}
                  >
                    {trans.selectMock}
                  </Button>
                  <Button
                    onClick={() => {
                      setActiveTab('upload')
                    }}
                    {...(activeTab === 'upload'
                      ? theme.createTransaction.tabButtonActive
                      : theme.createTransaction.tabButton)}
                  >
                    {trans.uploadPDFFile}
                  </Button>
                  <Button
                    onClick={() => {
                      setActiveTab('base64')
                    }}
                    {...(activeTab === 'base64'
                      ? theme.createTransaction.tabButtonActive
                      : theme.createTransaction.tabButton)}
                  >
                    {trans.pasteBase64}
                  </Button>
                </Nav>
                <CreateTransactionTab className={activeTab === 'mock' && 'active'}>
                  <InputLabelWrapper>
                    <label>{trans.existingPDFmocks}</label>
                    <DropdownWrapper>
                      <SelectInput
                        options={PDF_NAME_LIST}
                        value={selectedMockPdf}
                        onChange={(e: SelectOption) => setSelectedMockPdf(e)}
                        isDisabled={disabled}
                        {...theme.selectInput}
                      />
                    </DropdownWrapper>
                  </InputLabelWrapper>
                </CreateTransactionTab>
                <CreateTransactionTab className={activeTab === 'upload' && 'active'}>
                  <FilterRow>
                    <FixedFilter>
                      <input
                        type="file"
                        accept="application/pdf"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        onChange={handlePdfFileInputChange}
                        title={trans.PDFfileInput}
                      />
                      <div>{trans.uploadPDFFile}</div>
                      <Spacer size={10} />

                      <FakeFileInput onClick={handlePdfFileSelection} cursor={disabled ? 'not-allowed' : 'pointer'}>
                        {selectedFilePdf?.name || trans.clickToSelectFile}
                      </FakeFileInput>
                    </FixedFilter>
                    {/*<Spacer size={20}></Spacer>
                                 <FixedFilter>
                                  <BareButton
                                    disabled={fetching}
                                    onClick={handlePdfFileSelection}
                                  >
                                    Upload new PDF
                                  </BareButton>
                                </FixedFilter> */}
                  </FilterRow>
                </CreateTransactionTab>
                <CreateTransactionTab className={activeTab === 'base64' && 'active'}>
                  <div>{trans.pastePDFbase64}</div>
                  <Spacer size={10} />
                  <PDFTextArea
                    title={trans.PDFbase64Input}
                    rows={1}
                    autoCorrect="off"
                    autoComplete="off"
                    spellCheck="false"
                    value={selectedBase64Pdf}
                    onChange={(e) => setSelectedBase64Pdf(e.target.value)}
                    disabled={disabled}
                  />
                </CreateTransactionTab>
              </TabbedContent>
            </Section>

            <FormButtonRow>
              <Button
                onClick={() => {
                  setCurrentObj(initObj)
                  setConfigurationIDInputValue(configIdsList[0])
                  setPreferredLanguageInputValue(LANGUAGES_LIST[0])
                  setThemeIdInputValue(themeIdsList[0])
                  setRedirectUrlInputValue('')
                  setPhoneNumberInputValue('')
                  setEmailInputValue('')
                  setV3InputValue(V3_OPTIONS_LIST[0])
                }}
                disabled={fetching}
                {...theme.createTransaction.clearButton}
              >
                {trans.clear}
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={disabled || currentObjErrorValue}
                {...theme.createTransaction.createButton}
              >
                {trans.create}
              </Button>
            </FormButtonRow>
          </Content>
        </NewTransaction>
        <Results>
          <Heading>Results</Heading>
          <ResultListWrapper>
            {fetching && (
              <LoaderWrapper>
                <LoadingSpinner width="60px" {...theme.loadingSpinner} />
              </LoaderWrapper>
            )}
            {renderResults()}
          </ResultListWrapper>
        </Results>
      </MainContainer>
    </Wrap>
  )
})
