import { FC, PropsWithChildren } from 'react'
import { StyledDataValue, Label, ValueBox } from './DataValue.styles'

type Props = {
  label?: string
  data: string | number
}

export const DataValueString: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledDataValue>
        {props.label && <Label>{props.label}:</Label>}
        <ValueBox>{String(props.data).trim() !== '' ? String(props.data).trim() : '-'}</ValueBox>
    </StyledDataValue>
  )
}
