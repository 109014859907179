import { FC } from 'react'
import { Menu, MenuItem } from '@szhsin/react-menu'
import { svgs } from './icons/TagPillIcons'

import { StyledTagFilterDropdown, OpenButton, TagRow, TagIcon, TagName } from './TagFilterDropdown.styles'

export interface TagFilterObject {
  name: string
  state: 'include' | 'exclude' | ''
}

interface TagFilterDropdownProps {
  tags: Array<{ name: string; state: 'include' | 'exclude' | '' }>
  setTags: (tags: TagFilterObject[]) => void
  disabled: boolean
  placeholder: string
}

export const TagFilterDropdown: FC<TagFilterDropdownProps> = ({ tags, setTags, disabled, placeholder }) => {
  const renderTagState = (state: string) => {
    switch (state) {
      case 'include':
        return (
          <div style={{ color: 'green' }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
          </div>
        )
      case 'exclude':
        return (
          <div style={{ color: 'red' }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </div>
        )
      default:
        return (
          <div style={{ color: 'gray' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
            ></svg>
          </div>
        )
    }
  }

  const renderMenuItems = () =>
    tags.map((tag) => (
      <MenuItem
        key={tag.name}
        value={tag.name}
        onClick={(e) => {
          // Stop the `onItemClick` of root menu component from firing
          e.stopPropagation = true
          // Keep the menu open after this menu item is clicked
          e.keepOpen = true

          const newTags = [...tags]
          const tagIndex = tags.findIndex((t) => t.name === tag.name)
          switch (tag.state) {
            case '':
              newTags[tagIndex].state = 'include'
              break
            case 'include':
              newTags[tagIndex].state = 'exclude'
              break
            case 'exclude':
              newTags[tagIndex].state = ''
              break
          }
          setTags(newTags)
        }}
      >
        <TagRow>
          <TagIcon>{svgs[tag.name]}</TagIcon>
          <TagName>{tag.name}</TagName>
          {/* <Checkbox
            checked={tag.state === 'include'}
            indeterminate={tag.state === ''}
          /> */}

          {renderTagState(tag.state)}
        </TagRow>
      </MenuItem>
    ))

  return (
    <StyledTagFilterDropdown>
      <Menu menuButton={<OpenButton disabled={!tags?.length || disabled}>{placeholder}</OpenButton>}>
        {renderMenuItems()}
      </Menu>
    </StyledTagFilterDropdown>
  )
}
