import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { StyledFiltersToggleIcon } from './FiltersToggleIcon.styles'
import { useTheme } from 'styled-components'

type Props = {
  opened: boolean
  onClick: MouseEventHandler
}

export const FiltersToggleIcon: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <StyledFiltersToggleIcon opened={props.opened} onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
        <path d="M15.7193 31.5C24.2797 31.5 31.2193 24.5604 31.2193 16C31.2193 7.43959 24.2797 0.5 15.7193 0.5C7.15889 0.5 0.219299 7.43959 0.219299 16C0.219299 24.5604 7.15889 31.5 15.7193 31.5Z" fill={theme.colors.tertiary}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7193 17.6771L19.5829 13.8135L20.8557 15.0863L15.7193 20.2227L10.5829 15.0863L11.8557 13.8135L15.7193 17.6771Z" fill={theme.colors.secondary4}/>
      </svg>
    </StyledFiltersToggleIcon>
  )
}
