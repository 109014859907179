import { observer } from 'mobx-react'
import { Button, LoadingSpinner, SelectInput } from 'authflow-ui-engine'
import { FC, useContext, useEffect, useState, ChangeEvent, useRef } from 'react'
import { Navigate } from 'react-router-dom'
import { useDarkMode, useLocalStorage } from 'usehooks-ts'
import DateTimePicker from 'react-datetime-picker'
import { StoreContext } from '../../App'
import { Table } from './components/Table'
import { useTheme } from 'styled-components'
import { Spacer } from '../../shared/components/Spacer'
import { checkTranslation } from '../../shared/utils/handleTexts'
import { DateTimePickerStyled } from './DateTimePicker.styles'
import {
  DropdownWrapper,
  FillingFilter,
  FilterRow,
  FixedFilter,
  FixedFilterButton,
  Page,
  PageContainer,
  PageHeader,
  PageTitle,
  SearchInput,
  StyledPage,
  TenantSelector,
  Pagination,
  TableContainer,
  TitleRefreshWrapper,
  PageFiltersOpener,
} from './TransactionsPage.styles'
import { Sidebar, Overlay } from './SingleTransactionPage.styles'
import { SingleTransactionAuditLog } from './SingleTransactionAuditLog'
import { Menu } from './components/Menu'
import { MobileHeader } from './components/MobileHeader'
import { SelectOption } from '../../constants/SelectOption'
import { toSelectOptions, tenantsSort } from '../../helpers/selectHelpers'
import { RefreshIcon } from './components/RefreshIcon'
import { FiltersToggleIcon } from './components/FiltersToggleIcon'
import { TagFilterDropdown, TagFilterObject } from './components/TagFilterDropdown'
import useCheckMobile from '../../hooks/useCheckMobile'
import { statusOptions, columnsOptions } from './constants/TransactionFilters'
import { toast } from 'react-toastify'
import { dateStart, dateEnd, timezone } from '../../helpers/dateHelpers'
import { CloseIcon } from './components/CloseIcon'

export const TransactionsPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionsPage: trans } = store.TranslationsState.translations
  const theme = useTheme()

  const { isDarkMode } = useDarkMode()
  const isMobileView = useCheckMobile()

  const inputEls = useRef([])

  const { tenants, selectedTenant, tenantsWithAuditLogRights } = store.AppState
  const { transactions, total, limit, fetching } = store.MerchantsTable
  const {
    filter,
    configurationId,
    userRef,
    selectedStatus,
    transactionStart,
    transactionEnd,
    tagFilterArr,
    hiddenColumns,
    pageTokenArray,
    currentPageIndex,
    notification,
  } = store.Filters

  const { isLogged, isAuthorized, currentUserId } = store.Auth
  const [menuOpened] = useLocalStorage('menuOpened', true)
  const [currentInput, setCurrentInput] = useState<string>('')

  const [enableHiddenColumnsFilter] = useState(false)

  const [filtersOpened, setFiltersOpened] = useState(false)
  const [auditLogTransactionId, setAuditLogTransactionId] = useState<string>('')
  const [auditLogCreationUnixTimeSeconds, setAuditLogCreationUnixTimeSeconds] = useState<number>(null)
  const isSelectedTenantWithAuditLogRight = tenantsWithAuditLogRights.includes(selectedTenant)

  const [sidebarOpened, setSidebarOpened] = useState(false)

  const statusOptionsList = statusOptions.map((item) => ({ value: item.value, label: String(trans[item.value]) }))
  const selectedStatusIndex = statusOptionsList.findIndex((item) => item.value === selectedStatus)

  const toggleSidebar = (isOpened: boolean) => {
    if (isOpened) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'

    setSidebarOpened(isOpened)
  }

  const onTenantChange = (e: SelectOption) => {
    resetPagination()
    store.AppState.setSelectedTenant(e.value)
  }

  const inputRefHandler = (el: HTMLInputElement) => (inputEls.current[el?.id] = el)
  const inputOnFocusHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => setCurrentInput(e.target.id)
  const inputOnBlurHandler = () => setCurrentInput('')

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.Filters.setFilter(e.target.value)
    if (String(e.target.value).length >= 3 || String(e.target.value).length === 0) {
      store.Filters.setFilter(e.target.value)
    }
  }

  const onConfigurationIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.Filters.setConfigurationId(e.target.value)
    if (String(e.target.value).length >= 3 || String(e.target.value).length === 0) {
      store.Filters.setConfigurationId(e.target.value)
    }
  }

  const onUserRefChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.Filters.setUserRef(e.target.value)
    if (String(e.target.value).length >= 3 || String(e.target.value).length === 0) {
      store.Filters.setUserRef(e.target.value)
    }
  }

  const onStatusChange = (e: SelectOption) => store.Filters.setSelectedStatus(e.value)

  const onTagsChange = (tag: TagFilterObject[]) => store.Filters.setTagFilterArr(tag)

  const onHiddenColumnsChange = (e: SelectOption[]) => store.Filters.setHiddenColumns(e)

  const onTransactionStartChange = (date: Date) => {
    if (date || transactionStart !== dateStart) {
      store.Filters.setTransactionStart(date || dateStart)
    }
  }

  const onTransactionEndChange = (date: Date) => {
    if (date || transactionEnd !== dateEnd) {
      store.Filters.setTransactionEnd(date || dateEnd)
    }
  }

  useEffect(() => {
    store.MerchantsTable.getTransactions()
  }, [currentUserId])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    inputEls.current[currentInput] && inputEls.current[currentInput].focus()
  }, [fetching])

  const renderTenantSwitcher = (inFilters: boolean, hideOnMobile: boolean) => {
    if (tenants && tenants.length && !inFilters) {
      return (
        <TenantSelector hideOnMobile={hideOnMobile}>
          <span>{trans.clientId}:</span>
          <DropdownWrapper>
            <SelectInput
              options={toSelectOptions(tenants.sort(tenantsSort))}
              value={{
                value: selectedTenant || '',
                label: selectedTenant || trans.selectTenant,
              }}
              onChange={onTenantChange}
              {...theme.selectInput}
            />
          </DropdownWrapper>
        </TenantSelector>
      )
    } else if (tenants && tenants.length && inFilters) {
      return (
        <>
          <FixedFilter hideOnDesktop={true}>
            <div>{trans.clientId}</div>
            <Spacer size={5} />
            <DropdownWrapper>
              <SelectInput
                options={toSelectOptions(tenants.sort(tenantsSort))}
                value={{
                  value: selectedTenant || tenants[0],
                  label: selectedTenant || tenants[0],
                }}
                disabled={fetching}
                onChange={onTenantChange}
                {...theme.selectInput}
              />
            </DropdownWrapper>
          </FixedFilter>
        </>
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    if (notification.length) toast(notification)
  }, [notification])

  const resetFilters = () => {
    store.Filters.resetFilters()
  }

  const resetPagination = () => {
    store.Filters.setPageTokenArray([''])
    store.Filters.setCurrentPageIndex(0)
  }

  const renderPagination = () => {
    if (!transactions?.length)
      return <Pagination justifyContent="center">{!fetching && <span>{trans.noResults}</span>}</Pagination>

    if (pageTokenArray.length <= 1)
      return (
        <Pagination justifyContent="center">
          {!fetching && (
            <span>
              {1}-{transactions.length} {trans.of} {total}
            </span>
          )}
        </Pagination>
      )

    return (
      <Pagination>
        <Button
          onClick={() => {
            store.Filters.setCurrentPageIndex(currentPageIndex - 1)
          }}
          disabled={currentPageIndex <= 0 || fetching}
          {...theme.buttons.paginationButton}
        >
          {trans.prev}
        </Button>

        {!fetching && (
          <span>
            {transactions.length < limit ? (
              <>
                {currentPageIndex * limit + 1}-{currentPageIndex * limit + transactions.length} of {total}
              </>
            ) : (
              <>
                {currentPageIndex * limit + 1}-{(currentPageIndex + 1) * limit} of {total}
              </>
            )}
          </span>
        )}

        <Button
          onClick={() => {
            store.Filters.setCurrentPageIndex(currentPageIndex + 1)
          }}
          disabled={currentPageIndex >= pageTokenArray.length - 1 || fetching}
          {...theme.buttons.paginationButton}
        >
          {trans.next}
        </Button>
      </Pagination>
    )
  }

  return (
    <StyledPage>
      <Page menuOpened={menuOpened || sidebarOpened}>
        <PageHeader>
          <TitleRefreshWrapper>
            <PageTitle>{trans.pageTitle}</PageTitle>
            <div
              onClick={() => {
                if (!fetching) {
                  store.MerchantsTable.getTransactions()
                }
              }}
            >
              <RefreshIcon />
            </div>
          </TitleRefreshWrapper>
          {/* {renderTenantSwitcher(false, true)} */}
        </PageHeader>
        <Spacer size={19} mobileSize={0}></Spacer>
        <PageFiltersOpener>
          {trans.filters}
          <FiltersToggleIcon
            opened={filtersOpened}
            onClick={() => setFiltersOpened(!filtersOpened)}
          ></FiltersToggleIcon>
        </PageFiltersOpener>
        <PageContainer open={filtersOpened} enableHiddenColumnsFilter={enableHiddenColumnsFilter}>
          <FilterRow>
            {renderTenantSwitcher(true, false)}
            <FillingFilter>
              <div>{trans.transactionId}</div>
              <Spacer size={5} />
              <SearchInput
                isDarkTheme={isDarkMode}
                type="search"
                value={filter}
                disabled={fetching}
                onChange={onFilterChange}
                id="filterInput"
                ref={inputRefHandler}
                onFocus={inputOnFocusHandler}
                onBlur={inputOnBlurHandler}
              ></SearchInput>
            </FillingFilter>
            <FillingFilter>
              <div>{trans.configurationId}</div>
              <Spacer size={5} />
              <SearchInput
                isDarkTheme={isDarkMode}
                type="search"
                value={configurationId}
                disabled={fetching}
                onChange={onConfigurationIdChange}
                id="configurationIdInput"
                ref={inputRefHandler}
                onFocus={inputOnFocusHandler}
                onBlur={inputOnBlurHandler}
              ></SearchInput>
            </FillingFilter>
            <FillingFilter>
              <div>{trans[checkTranslation('externalUserRefId', selectedTenant) || 'externalReferenceId']}</div>
              <Spacer size={5} />
              <SearchInput
                isDarkTheme={isDarkMode}
                type="search"
                value={userRef}
                disabled={fetching}
                onChange={onUserRefChange}
                id="userRefInput"
                ref={inputRefHandler}
                onFocus={inputOnFocusHandler}
                onBlur={inputOnBlurHandler}
              ></SearchInput>
            </FillingFilter>
            <FixedFilter>
              <div>{trans.status}</div>
              <Spacer size={5} />
              <DropdownWrapper>
                <SelectInput
                  options={statusOptionsList}
                  value={statusOptionsList[selectedStatusIndex]}
                  isDisabled={fetching}
                  menuPlacement={'bottom'}
                  onChange={onStatusChange}
                  {...theme.selectInput}
                />
              </DropdownWrapper>
            </FixedFilter>

            <FixedFilter>
              <div>{trans.tags}</div>
              <Spacer size={5} />
              <TagFilterDropdown
                disabled={fetching}
                tags={tagFilterArr}
                setTags={onTagsChange}
                placeholder={trans.select}
              />
            </FixedFilter>

            <FixedFilter>
              <div>{trans.startDate}</div>
              <Spacer size={5} />
              <DateTimePickerStyled>
                <DateTimePicker
                  format="y-MM-dd HH:mm"
                  disabled={fetching}
                  openWidgetsOnFocus={false}
                  onChange={(date) => onTransactionStartChange(date)}
                  value={transactionStart}
                  disableClock={true}
                  showLeadingZeros={true}
                />
              </DateTimePickerStyled>
            </FixedFilter>
            <FixedFilter>
              <div>{trans.endDate}</div>
              <Spacer size={5} />
              <DateTimePickerStyled>
                <DateTimePicker
                  format="y-MM-dd HH:mm"
                  disabled={fetching}
                  openWidgetsOnFocus={false}
                  onChange={(date) => onTransactionEndChange(date)}
                  value={transactionEnd}
                  disableClock={true}
                  showLeadingZeros={true}
                />
              </DateTimePickerStyled>
            </FixedFilter>
            {enableHiddenColumnsFilter && (
              <FixedFilter>
                <div>{trans.hideColumns}</div>
                <Spacer size={5} />
                <DropdownWrapper>
                  <SelectInput
                    options={columnsOptions}
                    isMulti={true}
                    value={hiddenColumns}
                    isDisabled={fetching}
                    placeholder={trans.selectPlaceholder}
                    menuPlacement={isMobileView ? 'top' : 'bottom'}
                    onChange={onHiddenColumnsChange}
                    {...theme.selectInput}
                  />
                </DropdownWrapper>
              </FixedFilter>
            )}
            <FixedFilterButton>
              <CloseIcon onClick={() => resetFilters()} {...theme.buttons.clearFiltersButton}></CloseIcon>
              <Button onClick={() => resetFilters()} {...theme.buttons.clearFiltersButton}>
                {trans.clearFilters}
              </Button>
            </FixedFilterButton>
            <Spacer size={0} mobileSize={50} />
          </FilterRow>
        </PageContainer>
        <Spacer size={10} />
        <TableContainer>
          {!fetching ? (
            <Table
              transactions={transactions}
              timezone={timezone}
              onClickConfigurationId={(id: string) => store.Filters.setConfigurationId(id)}
              onClickOldTransaction={
                isSelectedTenantWithAuditLogRight
                  ? (id: string, timestamp: number) => {
                      setAuditLogTransactionId(id)
                      setAuditLogCreationUnixTimeSeconds(timestamp)
                      toggleSidebar(!sidebarOpened)
                    }
                  : undefined
              }
              hiddenColumns={hiddenColumns}
            />
          ) : (
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          )}
        </TableContainer>
        <Spacer size={10} />
        {renderPagination()}
      </Page>
      {isSelectedTenantWithAuditLogRight && auditLogTransactionId && (
        <>
          <Sidebar open={sidebarOpened} {...theme.sidebar}>
            <SingleTransactionAuditLog
              transactionId={auditLogTransactionId}
              timestamp={auditLogCreationUnixTimeSeconds}
              open={sidebarOpened}
              handleOnClose={() => toggleSidebar(false)}
            />
          </Sidebar>
          <Overlay visible={sidebarOpened} />
        </>
      )}
      <MobileHeader />
      <Menu />

      {isLogged && !isAuthorized ? (
        <Navigate to={`/unauthorized?title=${trans.notAuthorized}&description=${trans.notAuthorizedMessage}`} />
      ) : (
        <></>
      )}
      {!isLogged ? <Navigate to={'/login'} /> : <></>}
    </StyledPage>
  )
})
