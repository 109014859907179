import { action, makeAutoObservable, reaction, runInAction } from 'mobx'
import { statusOptions } from '../modules/logs/constants/TransactionFilters'
import { SelectOption } from '../constants/SelectOption'
import { TagFilterObject } from '../modules/logs/components/TagFilterDropdown'
import { axiosInstance } from '../methods/axiosConfig'
import { dateStart, dateEnd } from '../helpers/dateHelpers'
import { debounce } from '../helpers/debounceHelper'
import { makeLocalStorage } from '../helpers/localStorageHelpers'
import { RootStore } from './Root.store'

export class FiltersStore {
  filter = ''
  configurationId = ''
  userRef = ''
  selectedStatus = statusOptions[0].value
  transactionStart: Date = dateStart
  transactionEnd: Date = dateEnd
  hiddenColumns: SelectOption[] = []
  fetchedTags: string[] = []
  tagFilterArr: TagFilterObject[] = []
  configIds: SelectOption[] = []
  themeIds: SelectOption[] = []
  pageTokenArray = ['', '1', '2', '3', '4']
  currentPageIndex = 0
  notification = ''

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore

    makeLocalStorage(this, 'filtersStore', [
      'filter',
      'configurationId',
      'userRef',
      'selectedStatus',
      'hiddenColumns',
      'tagFilterArr',
      'configIds',
      'themeIds',
    ])

    reaction(
      () => [
        this.filter,
        this.configurationId,
        this.userRef,
        this.selectedStatus,
        this.transactionStart,
        this.transactionEnd,
        this.fetchedTags,
        this.tagFilterArr,
      ],

      debounce((): void => {
        this.setCurrentPageIndex(0)
        this.setPageTokenArray([])
      }, 1000)
    )
  }

  resetFilters(): void {
    runInAction(() => {
      this.filter = ''
      this.configurationId = ''
      this.userRef = ''
      this.selectedStatus = statusOptions[0].value
      this.transactionStart = dateStart
      this.transactionEnd = dateEnd
      this.tagFilterArr = this.tagFilterArr.map((tag) => ({ ...tag, state: '' }))
      this.hiddenColumns = []
    })
  }

  @action.bound setPageTokenArray = (tokenArray: string[]): void => {
    this.pageTokenArray = tokenArray
  }

  @action.bound setPageTokenByIndex = (index: number, token: string): void => {
    this.pageTokenArray[index] = token
  }

  @action.bound setCurrentPageIndex = (index: number): void => {
    runInAction(() => {
      this.currentPageIndex = index

      this.rootStore.MerchantsTable.getTransactions()
    })
  }

  @action.bound setNotification = (notification: string): void => {
    this.notification = notification
  }

  @action.bound setFilter = (filter: string): void => {
    this.filter = filter
  }

  @action.bound setConfigurationId = (configurationId: string): void => {
    this.configurationId = configurationId
  }

  @action.bound setUserRef = (userRef: string): void => {
    this.userRef = userRef
  }

  @action.bound setTransactionStart = (transactionStart: Date): void => {
    this.transactionStart = transactionStart
  }

  @action.bound setTransactionEnd = (transactionEnd: Date): void => {
    this.transactionEnd = transactionEnd
  }

  @action.bound setSelectedStatus = (selectedStatus: string): void => {
    this.selectedStatus = selectedStatus
  }

  @action.bound setHiddenColumns = (hiddenColumns: SelectOption[]): void => {
    this.hiddenColumns = hiddenColumns
  }

  @action.bound setFetchedTags = (tags: string[]): void => {
    this.fetchedTags = tags
  }

  @action.bound setTagFilterArr = (tagFilterArr: TagFilterObject[]): void => {
    this.tagFilterArr = tagFilterArr
  }

  @action.bound async fetchTags() {
    if (
      this.rootStore.Auth.currentUserId?.length &&
      this.rootStore.AppState.selectedTenant?.length &&
      this.rootStore.AppState.selectedTenant !== 'null' &&
      this.rootStore.AppState.selectedTenant !== 'undefined'
    ) {
      const res = await axiosInstance.get<{ items: string[] }>(`/tags`, {
        withCredentials: true,
        params: {
          tenantId: this.rootStore.AppState.selectedTenant,
        },
      })

      this.setFetchedTags(res.data.items)

      this.setTagFilterArr(
        res.data.items.map((tag) => {
          const selectedTag = this.tagFilterArr.find((item) => item.name === tag)

          return {
            name: tag,
            state: selectedTag ? selectedTag.state : '',
          }
        })
      )
    }
  }

  @action.bound setConfigIds = (configIds: SelectOption[]): void => {
    this.configIds = configIds
  }

  @action.bound setThemeIds = (themeIds: SelectOption[]): void => {
    this.themeIds = themeIds
  }

  @action.bound async fetchConfigIds() {
    if (
      this.rootStore.Auth.currentUserId?.length &&
      this.rootStore.AppState.selectedTenant?.length &&
      this.rootStore.AppState.selectedTenant !== 'null' &&
      this.rootStore.AppState.selectedTenant !== 'undefined'
    ) {
      try {
        const res = await axiosInstance.get<{ configurationIds: string[]; themeIds: string[] }>(`/tenants/attributes`, {
          withCredentials: true,
          params: {
            tenantId: this.rootStore.AppState.selectedTenant,
          },
        })

        this.setConfigIds(
          res.data.configurationIds.map((configId) => {
            return {
              label: configId,
              value: configId,
            }
          })
        )

        this.setThemeIds(
          res.data.themeIds.map((themeId) => {
            return {
              label: themeId,
              value: themeId,
            }
          })
        )
      } catch (e) {
        this.setConfigIds([])
        this.setThemeIds([])
      }
    }
  }
}
