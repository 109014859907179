import styled from 'styled-components'

export const StyledErrorIllustration = styled.svg`
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: ${({ theme }) => theme.colors.quaternary3};
  }

  .cls-3 {
    fill: ${({ theme }) => theme.colors.secondary3};
  }

  .cls-4,
  .cls-5 {
    fill: ${({ theme }) => theme.colors.primary};
  }
`
