import { FC, PropsWithChildren } from 'react'
import { TableRowExpandStyled } from './TableRowExpand.styles'
import { useTheme } from 'styled-components'

type Props = {
  rightPosition?: boolean
}

export const TableRowExpand: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <TableRowExpandStyled rightPosition={props.rightPosition}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z" fill={theme.colors.secondary2} />
      </svg>
    </TableRowExpandStyled>
  )
}
