export const statusOptions = [
  { value: 'all', label: 'all' },
  { value: 'completed', label: 'completed' },
  { value: 'pending', label: 'pending' },
  { value: 'expired', label: 'expired' },
]

export const columnsOptions = [
  { value: 'Transaction ID', label: 'Transaction ID' },
  { value: 'Configuration ID', label: 'Configuration ID' },
  { value: 'Application Number', label: 'Application Number' },
  { value: 'Create date', label: 'Create date' },
  { value: 'Completed date', label: 'Completed date' },
  { value: 'Status', label: 'Status' },
  { value: 'Tags', label: 'Tags' },
]
