import { FC, PropsWithChildren } from 'react'
import { Button } from 'authflow-ui-engine'
import { StyledDataValue, Label } from './DataValue.styles'
import { useTheme } from 'styled-components'

type Props = {
  label?: string
  data: { webUrl: string }
  isV3?: boolean
}

export const DataValueDownload: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <StyledDataValue>
      {props.label && <Label>{props.label}:</Label>}
      <Button
        onClick={() => window.open(`${props.data.webUrl}${props.isV3 ? '&isV3=true' : ''}`, '_blank')}
        {...theme.buttons.downloadButton}
      >
        Download
      </Button>
    </StyledDataValue>
  )
}
