import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { StyledTransactionHistoryButton } from './TransactionHistoryButton.styles'

type Props = {
  onClick: MouseEventHandler
  title: string
}

export const TransactionHistoryButton: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledTransactionHistoryButton
      onClick={props.onClick}
      title={props.title}
    >
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.707 0.5C24.707 0.223858 24.4832 0 24.207 0H8.20703V11L1.91419 11L4.20711 8.70708C4.40237 8.51182 4.40237 8.19524 4.20711 7.99998V7.99998C4.01184 7.80471 3.69526 7.80471 3.5 7.99998L0 11.5L3.5 15C3.69526 15.1952 4.01184 15.1952 4.20711 15V15C4.40237 14.8047 4.40237 14.4881 4.20711 14.2929L1.91424 12L8.20703 12V24H24.207C24.4832 24 24.707 23.7761 24.707 23.5V23.5C24.707 23.2239 24.4832 23 24.207 23H9.20703V1H24.207C24.4832 1 24.707 0.776142 24.707 0.5V0.5Z" fill="currentColor"/>
<path d="M11.707 3.5C11.707 3.77614 11.9309 4 12.207 4H21.207C21.4832 4 21.707 3.77614 21.707 3.5V3.5C21.707 3.22386 21.4832 3 21.207 3H12.207C11.9309 3 11.707 3.22386 11.707 3.5V3.5Z" fill="currentColor"/>
<path d="M21.707 20.5C21.707 20.7761 21.4832 21 21.207 21H12.207C11.9309 21 11.707 20.7761 11.707 20.5V20.5C11.707 20.2239 11.9309 20 12.207 20H21.207C21.4832 20 21.707 20.2239 21.707 20.5V20.5Z" fill="currentColor"/>
<path d="M20.8341 7.87745C19.7929 7.09245 18.4953 6.71459 17.1897 6.81623C15.8842 6.91788 14.6624 7.49187 13.7584 8.42833C13.0234 9.18963 12.5404 10.147 12.3631 11.1736L11.788 10.5836C11.5966 10.3873 11.2836 10.3797 11.0829 10.5663V10.5663C10.8763 10.7584 10.8673 11.0825 11.0629 11.2858L12.8006 13.0922L14.6079 11.3952C14.8106 11.2048 14.8195 10.8858 14.6278 10.6844V10.6844C14.437 10.484 14.1198 10.4762 13.9194 10.667L13.3946 11.1666C13.5603 10.4018 13.9372 9.69164 14.4904 9.11866C15.2225 8.36029 16.2119 7.89546 17.2692 7.81315C18.3265 7.73083 19.3773 8.03683 20.2205 8.67255C21.0637 9.30827 21.6401 10.229 21.8393 11.2586C22.0385 12.2882 21.8464 13.3541 21.3 14.2525C20.7535 15.1508 19.891 15.8184 18.8776 16.1274C17.8642 16.4364 16.7711 16.3651 15.8075 15.9272C15.3573 15.7226 14.9501 15.4446 14.601 15.1084C14.4023 14.9171 14.0902 14.882 13.8751 15.0547V15.0547C13.6565 15.2302 13.6218 15.5524 13.8198 15.7509C14.2672 16.1992 14.7962 16.5682 15.3848 16.8358C16.5747 17.3765 17.9245 17.4645 19.1759 17.083C20.4273 16.7014 21.4923 15.8771 22.1671 14.7677C22.8419 13.6584 23.079 12.3421 22.8331 11.0708C22.5871 9.79947 21.8754 8.66246 20.8341 7.87745Z" fill="currentColor"/>
<path d="M18.1139 9.56422C18.1139 9.28483 17.8875 9.05834 17.6081 9.05834V9.05834C17.3287 9.05834 17.1022 9.28483 17.1022 9.56422V12.2654L18.0523 13.2045C18.2482 13.3981 18.5634 13.3981 18.7593 13.2045V13.2045C18.9585 13.0076 18.9585 12.686 18.7593 12.4891L18.1139 11.8512V9.56422Z" fill="currentColor"/>
</svg>
    </StyledTransactionHistoryButton>
  )
}
