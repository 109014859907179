import { createBrowserRouter } from 'react-router-dom'
import { LoginPage } from '../login/LoginPage'
import { ErrorPage } from '../error/ErrorPage'
import { CreateTransactionPage } from '../createTransaction/CreateTransactionPage'
import { SingleTransactionPage } from './SingleTransactionPage'
import { TransactionsPage } from './TransactionsPage'
import { SettingsPage } from '../settings/SettingsPage'

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '/unauthorized',
    element: <ErrorPage />,
  },
  {
    path: '/unauthorized/:message',
    element: <ErrorPage />,
  },
  {
    path: '/transactions',
    element: <TransactionsPage />,
  },
  {
    path: '/transactions/:transactionId',
    element: <SingleTransactionPage />,
    // eslint-disable-next-line @typescript-eslint/require-await
    loader: async ({ params }) => {
      return null
    },
    // eslint-disable-next-line @typescript-eslint/require-await
    action: async ({ params }) => {
      return null
    },
  },
  {
    path: '/create-transaction',
    element: <CreateTransactionPage />,
  },
  {
    path: '/settings',
    element: <SettingsPage />,
  },
])
