import { observer } from 'mobx-react'
import { SelectInput } from 'authflow-ui-engine'
import { FC, useContext, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { StoreContext } from '../../App'
import { Spacer } from '../../shared/components/Spacer'

import {
  DropdownWrapper,
  FilterRow,
  FixedFilter,
  Page,
  PageContainer,
  PageHeader,
  PageTitle,
  StyledPage,
  TitleRefreshWrapper,
} from '../logs/TransactionsPage.styles'
import { Menu } from '../logs/components/Menu'
import { MobileHeader } from '../logs/components/MobileHeader'
import { SelectOption } from '../../constants/SelectOption'
import { useTheme } from 'styled-components'
import { tagsOptions } from './constants/Settings'

export const SettingsPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { settingsPage: trans } = store.TranslationsState.translations
  const theme = useTheme()
  const { tagsSettings } = store.Settings

  const tagsOptionsList = tagsOptions.map((item) => ({ value: item.value, label: String(trans[item.value]) }))
  const tagsSettingsIndex = tagsOptionsList.findIndex((item) => item.value === tagsSettings)

  const { isLogged, isAuthorized } = store.Auth

  const onTagSettingsChange = (e: SelectOption) => store.Settings.setTagsSettings(e.value)

  return (
    <StyledPage>
      <Page>
        <PageHeader>
          <TitleRefreshWrapper>
            <PageTitle>{trans.pageTitle}</PageTitle>
          </TitleRefreshWrapper>
        </PageHeader>
        <Spacer size={19} mobileSize={0}></Spacer>
        <PageContainer open>
          <FilterRow>
            <FixedFilter>
              <div>{trans.tagsSettings}</div>
              <Spacer size={5} />
              <DropdownWrapper>
                <SelectInput
                  options={tagsOptionsList}
                  value={tagsOptionsList[tagsSettingsIndex]}
                  menuPlacement={'bottom'}
                  onChange={onTagSettingsChange}
                  {...theme.selectInput}
                />
              </DropdownWrapper>
            </FixedFilter>
          </FilterRow>
        </PageContainer>
      </Page>
      <MobileHeader />
      <Menu />

      {isLogged && !isAuthorized ? (
        <Navigate to={`/unauthorized?title=${trans.notAuthorized}&description=${trans.notAuthorizedMessage}`} />
      ) : (
        <></>
      )}
      {!isLogged ? <Navigate to={'/login'} /> : <></>}
    </StyledPage>
  )
})
