import { FC, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'

import { StyledMobileHeader, StyledLogo } from './MobileHeader.styles'
import { Logo } from '../../../shared/components/Logo'

export const MobileHeader: FC<PropsWithChildren> = observer(() => {
  return (
    <StyledMobileHeader>
        <StyledLogo>
            <Logo />
        </StyledLogo>
    </StyledMobileHeader>
  )
})
