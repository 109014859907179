import { observer } from 'mobx-react'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Popup, Button } from 'authflow-ui-engine'
import { Link, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { axiosInstance } from '../../methods/axiosConfig'
import { StoreContext } from '../../App'
import { DataValueString } from './components/DataValueString'
import { DataValuePills } from './components/DataValuePills'
import { DataValueDownload } from './components/DataValueDownload'
import { DataJsonDownload } from './components/DataJsonDownload'
import { Spacer } from '../../shared/components/Spacer'
import {
  Grid,
  GridItem,
  Page,
  PageContainer,
  PageHeader,
  PageTitle,
  StyledPage,
  Sidebar,
} from './SingleTransactionPage.styles'
import { StyledDataValue } from './components/DataValue.styles'
import { Menu } from './components/Menu'
import { MobileHeader } from './components/MobileHeader'
import { DownloadJSONButton } from './components/DownloadJSONButton'
import { toast } from 'react-toastify'

import { SingleTransactionAuditLog } from './SingleTransactionAuditLog'
import { AuditLogButton } from './components/AuditLogButton'
import { DeletePiiDataButton } from './components/DeletePiiDataButton'
import { TransactionHistoryButton } from './components/TransactionHistoryButton'
import { SingleTransactionHistory } from './SingleTransactionHistory'
import { replaceValue, paddedCamel } from '../../shared/utils/handleTexts'
import { useTheme } from 'styled-components'
import { getFormatedDate } from '../../shared/utils/handleDate'
import { GoBackIcon } from './components/GoBackIcon'
import { ModularizationIcon } from './components/icons/ModularizationIcon'

interface TransactionDataInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

export const SingleTransactionPage: FC = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { singleTransactionPage: trans } = store.TranslationsState.translations
  const theme = useTheme()

  const { tenantsWithAuditLogRights, tenantsWithPurgePiiDataRights } = store.AppState

  const { isLogged, isAuthorized } = store.Auth
  const [menuOpened] = useLocalStorage('menuOpened', true)

  const transactionId = location.pathname.split('/')[2]

  const params = new URL(document.location.href).searchParams
  const selectedTenant = params.get('tenantId')
  const isV3 = params.get('isV3') === 'on'

  const isSelectedTenantWithAuditLogRight = tenantsWithAuditLogRights.includes(selectedTenant)
  const isSelectedTenantWithPurgePiiDataRight = tenantsWithPurgePiiDataRights.includes(selectedTenant)

  const [sidebarAuditLogOpened, setSidebarAuditLogOpened] = useState(false)
  const [sidebarStepsOpened, setSidebarStepsOpened] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [transactionData, setTransactionData] = useState<TransactionDataInterface>({})
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const toggleAuditLogSidebar = (isOpened: boolean) => {
    if (isOpened) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'

    setSidebarAuditLogOpened(isOpened)
  }

  const toggleStepsSidebar = (isOpened: boolean) => {
    if (isOpened) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'

    setSidebarStepsOpened(isOpened)
  }

  const deletePiiData = useCallback(async () => {
    await axiosInstance.delete(`/transactions/${transactionId}/pii-data`, {
      withCredentials: true,
      params: {
        tenantId: selectedTenant,
        isV3,
      },
    })
  }, [selectedTenant])

  const fetchTransaction = useCallback(async () => {
    const res = await axiosInstance.get(`/transactions/${transactionId}`, {
      withCredentials: true,
      params: {
        tenantId: selectedTenant,
        isV3,
      },
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setTransactionData(res.data)
  }, [selectedTenant])

  let downloadData = {}

  const addDownloadData = (name: string, data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    downloadData[name] = data
    downloadData = Object.fromEntries(Object.entries(downloadData).sort())
  }

  const renderValue = (name: string, data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dataType = Object.prototype.toString.call(data)

    switch (dataType) {
      case '[object Object]': {
        if ('webUrl' in data) {
          addDownloadData(name, data)
          return
        }

        return (
          <StyledDataValue>
            <h3>{paddedCamel(replaceValue(name, selectedTenant))}:</h3>
            {Object.keys(data).map((propertyName) => renderValue(propertyName, data[propertyName]))}
          </StyledDataValue>
        )
      }
      case '[object Array]': {
        if (name === 'accountInformation' || name === 'report') {
          addDownloadData(name, data)
          return
        }

        if (name === 'tags') {
          return <DataValuePills label={paddedCamel(replaceValue(name, selectedTenant))} data={data as string[]} />
        }

        return (
          <StyledDataValue>
            <h3>{paddedCamel(replaceValue(name, selectedTenant))}:</h3>
            {data.map((item, index: number) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const dataType2 = Object.prototype.toString.call(item)

              switch (dataType2) {
                case '[object Object]': {
                  if ('webUrl' in item) {
                    return <DataValueDownload data={item as { webUrl: string }} isV3={isV3} />
                  }
                  return (
                    <StyledDataValue>
                      {Object.keys(item).map((propertyName) => renderValue(propertyName, item[propertyName]))}
                    </StyledDataValue>
                  )
                }
                default: {
                  return <DataValueString data={String(item)} />
                }
              }
            })}
          </StyledDataValue>
        )
      }
      default: {
        if (name.includes('UnixTimeSeconds')) {
          const dateStart = getFormatedDate(data as number)
          return <DataValueString label={paddedCamel(replaceValue(name, selectedTenant))} data={String(dateStart)} />
        }
        return <DataValueString label={paddedCamel(replaceValue(name, selectedTenant))} data={String(data)} />
      }
    }
  }

  const renderDownloadValue = (name: string, data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dataType = Object.prototype.toString.call(data)

    switch (dataType) {
      case '[object Object]': {
        if ('webUrl' in data) {
          return <DataValueDownload label={paddedCamel(name)} data={data as { webUrl: string }} isV3={isV3} />
        }

        return
      }
      case '[object Array]': {
        if (name === 'accountInformation' || name === 'report') {
          return (
            <DataJsonDownload
              label={paddedCamel(name)}
              data={JSON.stringify(data)}
              fileName="accountInformation.json"
            />
          )
        }

        return
      }
      default: {
        return
      }
    }
  }

  const renderProviderData = (name: string, data: unknown) => {
    downloadData = {}
    return (
      <GridItem key={name}>
        <h2>{paddedCamel(replaceValue(name, selectedTenant))}</h2>
        {Object.keys(data).map((propertyName) => {
          return renderValue(propertyName, data[propertyName])
        })}
        {Object.keys(downloadData).map((propertyName) => {
          return renderDownloadValue(propertyName, data[propertyName])
        })}
      </GridItem>
    )
  }

  const renderBaseData = (data: unknown) => {
    downloadData = {}
    return (
      <GridItem key="basicData">
        <h2>{paddedCamel(replaceValue(trans.basicInformation, selectedTenant))}</h2>
        {Object.keys(data).map((name) => {
          return <>{renderValue(name, data[name])}</>
        })}
        {Object.keys(downloadData).map((propertyName) => {
          return renderDownloadValue(propertyName, data[propertyName])
        })}
      </GridItem>
    )
  }

  const renderTransaction = () => {
    if (Object.keys(transactionData).length !== 0 && transactionData.providers) {
      const { providers, ...basicData } = transactionData

      const providerNames = Object.keys(providers)
      const providerLengths = providerNames.map((name) => {
        return {
          name: name,
          howlong: JSON.stringify(providers[name]).length,
        }
      })
      const providersSortedByLength = providerLengths.sort((a, b) => a.howlong - b.howlong)

      return (
        <Grid>
          {renderBaseData(basicData)}
          {providersSortedByLength.map((providerConfig) => {
            return renderProviderData(providerConfig.name, providers[providerConfig.name])
          })}
        </Grid>
      )
    } else {
      return <span>{trans.unableToFetchTransaction}</span>
    }
  }

  useEffect(() => {
    if (selectedTenant?.length && !fetching && Object.keys(transactionData).length === 0) {
      setFetching(true)
      fetchTransaction()
        .finally(() => setFetching(false))
        .catch(() => toast(trans.unableToFetchTransaction))
    }
  }, [fetchTransaction, navigate, location, selectedTenant, transactionData])

  return (
    <StyledPage>
      <Popup
        visibility={isPopupVisible}
        handleVisibility={setIsPopupVisible}
        {...theme.popup}
        title={trans.deleteModalTitle}
        footer={
          <>
            <Button
              {...theme.buttons.popupPrimaryButton}
              onClick={() => {
                setDeleting(true)
                setIsPopupVisible(false)
                deletePiiData()
                  .finally(() => {
                    setDeleting(false)
                    setTransactionData({})
                  })
                  .catch(() => toast(String(trans.unableToDeletePiiData)))
              }}
            >
              {trans.confirm}
            </Button>
            <Button {...theme.buttons.popupDeclineButton} onClick={() => setIsPopupVisible(false)}>
              {trans.decline}
            </Button>
          </>
        }
      ></Popup>
      <Page menuOpened={menuOpened || sidebarAuditLogOpened || sidebarStepsOpened}>
        {!fetching ? (
          <>
            <PageHeader>
              <PageTitle>
                <Link to="/transactions" title={trans.goBack}>
                  <GoBackIcon />
                </Link>
                {isV3 && <ModularizationIcon />}
                {trans.transaction} {transactionId}
                <DownloadJSONButton data={transactionData} filename={transactionId + '.json'} />
                {isSelectedTenantWithAuditLogRight && (
                  <AuditLogButton
                    title={trans.showAuditLog}
                    onClick={() => toggleAuditLogSidebar(!sidebarAuditLogOpened)}
                  >
                    {trans.events}
                  </AuditLogButton>
                )}
                <TransactionHistoryButton
                  title={trans.showTransactionHistory}
                  onClick={() => toggleStepsSidebar(!sidebarStepsOpened)}
                >
                  {trans.steps}
                </TransactionHistoryButton>
                {isSelectedTenantWithPurgePiiDataRight && (
                  <DeletePiiDataButton
                    disabled={deleting}
                    title={trans.deletePiiData}
                    onClick={() => setIsPopupVisible(true)}
                  />
                )}
              </PageTitle>
            </PageHeader>
            <Spacer size={19} mobileSize={0}></Spacer>
            <PageContainer>{renderTransaction()}</PageContainer>
            <Spacer size={30}></Spacer>
          </>
        ) : (
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        )}
      </Page>
      <Sidebar open={sidebarAuditLogOpened} {...theme.sidebar}>
        <SingleTransactionAuditLog
          transactionId={transactionId}
          timestamp={
            transactionData.creationUnixTimeSeconds ? Number(transactionData.creationUnixTimeSeconds) : undefined
          }
          open={sidebarAuditLogOpened}
          handleOnClose={() => toggleAuditLogSidebar(false)}
        />
      </Sidebar>

      <Sidebar open={sidebarStepsOpened} {...theme.sidebar} width="50%">
        <SingleTransactionHistory
          transactionId={transactionId}
          open={sidebarStepsOpened}
          handleOnClose={() => toggleStepsSidebar(false)}
          isV3={isV3}
        />
      </Sidebar>
      <MobileHeader />
      <Menu />

      {isLogged && !isAuthorized ? (
        <Navigate to={`/unauthorized?title=${trans.notAuthorized}&description=${trans.notAuthorizedMessage}`} />
      ) : (
        <></>
      )}
      {!isLogged ? <Navigate to={'/login'} /> : <></>}
    </StyledPage>
  )
})
