import styled from 'styled-components'

export const StyledDataValue = styled.div.attrs({ className: 'data-value' })`
  width: 100%;
  margin-bottom: 0px;
  background-color: ${({ theme }) => theme.colors.quaternary};
  /* border-left: 1px solid ${({ theme }) => theme.colors.quinary}; */
  /* border-right: 2px solid ${({ theme }) => theme.colors.quinary}; */
  padding: 0 15px 15px;

  &:first-of-type {
    padding-top: 15px;
  }

  > h3 {
    font-size: 18px;
    text-transform: capitalize;
    background-color: ${({ theme }) => theme.colors.quinary};
    padding: 8px 8px 8px 10px;
  }

  > .data-value {
    margin-bottom: 0px;
    border: 2px solid ${({ theme }) => theme.colors.quinary};
    border-top: none;
    border-bottom: none;

    .value-box {
      width: 100%;
    }

    &:first-child {
      border-top: 2px solid ${({ theme }) => theme.colors.quinary};
    }

    &:last-child {
      border-bottom: 2px solid ${({ theme }) => theme.colors.quinary};
    }

    > .data-value {
      margin-bottom: 0px;
      border: 2px solid ${({ theme }) => theme.colors.quinary};
      border-top: none;
      border-bottom: none;

      .value-box {
        width: 100%;
      }

      &:first-child {
        border-top: 2px solid ${({ theme }) => theme.colors.quinary};
      }

      &:last-child {
        border-bottom: 2px solid ${({ theme }) => theme.colors.quinary};
      }
    }
  }
`

export const Label = styled.div`
  width: 100%;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: capitalize;
`

export const ValueBox = styled.div.attrs({ className: 'value-box' })`
  width: 100%;
  box-sizing: border-box;
  padding: 9px 10px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  text-wrap: pretty;
  word-break: break-all;
`

export const ValueBoxBoolean = styled.div`
  width: 100%;
`
