import { FC, PropsWithChildren } from 'react'
import { StyledTextInput } from './TextInput.styles'

type Props = {
  type: 'text' | 'url' | 'email'
  defaultValue?: string
  value?: string
  name?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  onChange?: (number) => void
  placeholder?: string
}

export const TextInput: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledTextInput
      type={props.type || 'text'}
      defaultValue={props.defaultValue}
      value={props.value}
      name={props.name}
      disabled={!!props.disabled}
      required={!!props.required}
      readOnly={!!props.readOnly}
      onChange={(e) => props.onChange(e.target.value)}
      placeholder={props.placeholder}
    >
      {props.children}
    </StyledTextInput>
  )
}
