import { FC, PropsWithChildren } from 'react'
import { StyledDataValue, Label, ValueBox } from './DataValue.styles'
import { Pills } from './Pills'

type Props = {
  label?: string
  data: string[]
}

export const DataValuePills: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledDataValue>
      {props.label && <Label>{props.label}:</Label>}
      <ValueBox>
        <Pills tags={props.data} rowId="0" position="bottom" hoverPills={false} initialTagsSettings="tags" />
      </ValueBox>
    </StyledDataValue>
  )
}
