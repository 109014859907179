import styled, { css } from 'styled-components'
import { Animatable } from '../../../shared/themes/common.styles'

export const StyledFiltersToggleIcon = styled.div<{
  opened: boolean
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  outline: none;

  ${({ opened }) =>
    opened &&
    css`
      transform: rotate(180deg);
    `}

  ${({ opened }) =>
    !opened &&
    css`
      transform: rotate(0deg);
    `}

  ${Animatable}
`
