import styled, { css } from 'styled-components'
import { Animatable } from '../../../shared/themes/common.styles'

export const TableRowExpandStyled = styled.div<{
  rightPosition?: boolean
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline-block;

  ${({ rightPosition }) =>
    rightPosition &&
    css`
      transform: rotate(-180deg);
    `}

  ${({ rightPosition }) =>
    !rightPosition &&
    css`
      transform: rotate(0deg);
    `}

  ${Animatable}
`
