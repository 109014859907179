import { css } from 'styled-components'

export const Animatable = css`
  ${({ theme }) =>
    theme.animations.enabled &&
    css`
      transition: all ${({ theme }) => theme.animations.duration}
        ${({ theme }) => theme.animations.function};
    `}
`
