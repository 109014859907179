import styled, { css } from 'styled-components'
import { Animatable } from '../../shared/themes/common.styles'

export const StyledPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    display: block;
    position: relative;
  }
`

export const Sidebar = styled.div<{
  open: boolean
  width?: string
  boxShadow?: string
}>`
  opacity: 1;
  position: fixed;
  right: 0;
  width: ${(props) => props.width || '75%'};
  height: 100%;
  z-index: 3;
  top: 0;
  overflow: auto;
  box-shadow: ${(props) => props.boxShadow || '0px 0px 10px 0px white'};

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => (theme.colors.scrollTrackColor ? theme.colors.scrollTrackColor : '#ebebeb')};
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ${({ theme }) => (theme.colors.scrollHandleColor ? theme.colors.scrollHandleColor : '#6d6d6d')};
  }

  ${({ open }) =>
    !open &&
    css`
      position: fixed;
      height: 100%;
      right: -100%;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${Animatable}
`

export const Page = styled.div<{
  menuOpened?: boolean
}>`
  flex: 1;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  padding: 39px 30px 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    min-height: 100%;
    padding: 0;
    margin-top: 65px;
  }

  ${Animatable}
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 30px;
    justify-content: space-between;
  }
`

export const PageTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 1.88rem;
  line-height: 1.22;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.25rem;
    padding: 10px 0;
  }
`

export const PageContainer = styled.div<{ noPadding?: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  font-size: 0.88rem;
  border-radius: 17px;

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 15px 20px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    border-radius: 0;
  }
`

export const Table = styled.table`
  table-layout: fixed;
  border: 1px solid;
  width: 90%;
  font-size: 1rem;

  thead,
  tbody {
    display: block;
  }

  tbody {
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  th,
  td {
    border: 1px solid;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(30%, 1fr) minmax(30%, 1fr) minmax(30%, 1fr);
  width: 100%;
  font-size: 1rem;
  column-count: 3;
  grid-gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`

export const GridHeader = styled.div`
  padding: 10px 14px 10px 10px;
  position: sticky;
`

export const GridItem = styled.div`
  padding: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 20px;
  max-width: 100%;
  break-inside: avoid;
  border: 2px solid ${({ theme }) => theme.colors.quinary};
  border-top: none;

  h2 {
    text-transform: capitalize;
    background-color: ${({ theme }) => theme.colors.quinary};
    padding: 8px 8px 8px 21px;
  }
`

export const TransactionInfo = styled.div`
  display: block;
  box-sizing: border-box;
`

export const Overlay = styled.div<{
  visible: boolean
}>`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  background: rgba(12, 1, 27, 0.59);
  backdrop-filter: blur(8px);

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  ${({ visible }) =>
    !visible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

    ${Animatable};
`
