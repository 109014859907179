import { FC, useContext, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { StoreContext } from '../../App'
import { Spacer } from '../../shared/components/Spacer'
import { Menu } from '../logs/components/Menu'
import { MobileHeader } from '../logs/components/MobileHeader'
import { Page, PageHeader, PageTitle, StyledPage } from './CreateTransactionPage.styles'
import { RequestForm } from './components/RequestForm'
import { observer } from 'mobx-react'

type Tabs = 'object-mode' | 'form-mode'

export const CreateTransactionPage: FC = observer(() => {
  const store = useContext(StoreContext)

  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { isUserWithAnyWriteRights } = store.AppState
  const { isAuthorized, isLogged } = store.Auth

  const [activeTab, setActiveTab] = useState<Tabs>('object-mode')

  return (
    <StyledPage>
      <Page>
        <PageHeader>
          <PageTitle>{trans.createTransaction}</PageTitle>
        </PageHeader>
        <Spacer size={19}></Spacer>

        <RequestForm />
      </Page>
      <MobileHeader />
      <Menu />
      {isLogged && !isAuthorized ? (
        <Navigate to={`/unauthorized?title=${trans.notAuthorized}}&description=${trans.notAuthorizedMessage}`} />
      ) : (
        <></>
      )}
      {isLogged && isUserWithAnyWriteRights ? <></> : <Navigate to="/unauthorized" />}
      {!isLogged ? <Navigate to={'/login'} /> : <></>}
    </StyledPage>
  )
})
