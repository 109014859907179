import { FC, MouseEventHandler, PropsWithChildren, useLayoutEffect, useMemo, useContext, useState } from 'react'
import { PillContainer, Pill, PillDots, PillsHover } from './Pills.styles'
import { StoreContext } from '../../../App'
import { TagPill } from './TagPill'

type Props = {
  rowId: string
  tags: string[]
  position?: 'top' | 'bottom'
  hoverPills?: boolean
  onClick?: MouseEventHandler
  initialTagsSettings?: string
}

export const Pills: FC<PropsWithChildren<Props>> = ({
  tags,
  rowId,
  position,
  hoverPills,
  initialTagsSettings,
}: Props) => {
  const store = useContext(StoreContext)

  const { tagsSettings } = store.Settings
  const [isHovering, setIsHovering] = useState(false)
  const [pillsWidths, setPillsWidths] = useState<number[]>([])
  const localWidthsState = JSON.parse(localStorage.getItem('tableWidthsState')) as { [key: string]: number }

  const localWidth = localWidthsState['--Tags-size'] - 40

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const findIndex = (arr: number[], limit: number) => {
    let sum = 0
    const idx = arr.findIndex((num) => (sum += num) >= limit)
    return idx >= 0 ? idx : -1
  }

  useLayoutEffect(() => {
    const tagsContainer = document.getElementById(`tags-container-${rowId}`)
    if (tagsContainer) {
      const tagPills = tagsContainer.getElementsByClassName('tag-pill')
      const tagPillsWidths: number[] = [...tagPills].map((pill: HTMLElement) => pill.clientWidth)
      setPillsWidths(tagPillsWidths)
    }
  }, [])

  const lastIndex = useMemo(() => {
    if (pillsWidths.length) {
      return findIndex(pillsWidths, localWidth)
    }
    return -1
  }, [localWidth, pillsWidths])

  const parsePillTitle = (tags: string[]) => {
    if (!tags?.length) return ''

    return tags.join(', ')
  }

  if (!tags?.length) return <></>

  if (!hoverPills) {
    return (
      <PillContainer id="tags-container" wrap="wrap">
        {[...tags.slice(0, tags.length)].map((tag, index) => {
          return (
            <Pill key={index} className="tag-pill" title={tag}>
              <TagPill tagsSettings={initialTagsSettings || tagsSettings} tag={tag} />
            </Pill>
          )
        })}
      </PillContainer>
    )
  }

  return (
    <PillContainer id={`tags-container-${rowId}`} wrap="nowrap">
      {[...tags.slice(0, tags.length)].map((tag, index) => {
        return (
          <Pill key={index} className={`tag-pill ${lastIndex > -1 && lastIndex <= index ? 'hidden' : ''}`} title={tag}>
            <TagPill tagsSettings={initialTagsSettings || tagsSettings} tag={tag} />
          </Pill>
        )
      })}

      {lastIndex > -1 && lastIndex < tags.length && (
        <PillDots
          key={`${rowId}_dots`}
          className="tag-pill"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onTouchStart={handleMouseOver}
          onTouchEnd={handleMouseOut}
        >
          ...
        </PillDots>
      )}
      {lastIndex > -1 && lastIndex < tags.length && isHovering && (
        <PillsHover position={position}>
          {[...tags.slice(lastIndex, tags.length)].map((tag, index) => {
            return (
              <Pill key={index} className="tag-pill" title={tag}>
                <TagPill tagsSettings={initialTagsSettings || tagsSettings} tag={tag} />
              </Pill>
            )
          })}
        </PillsHover>
      )}
    </PillContainer>
  )
}
