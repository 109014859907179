import { FC, useCallback, useContext, useEffect, useState } from 'react'
import {
  Button,
  LoadingSpinner,
} from 'authflow-ui-engine'
import qs from 'qs'
import { axiosInstance } from '../../methods/axiosConfig'
import { StoreContext } from '../../App'
import { Spacer } from '../../shared/components/Spacer'
import { AuditLogTable } from './components/AuditLogTable'
import {
  Page,
  PageHeader,
  PageTitle,
  StyledPage,
  Pagination,
  TableContainer,
  TitleRefreshWrapper,
} from './TransactionsPage.styles'
import { parseQueryParams } from '../../methods/paramsComposer'
import { EventLogsItem } from './SingleTransactionPage.interface'
import { RefreshIcon } from './components/RefreshIcon'

import { toast } from 'react-toastify'
import { CloseIcon } from './components/CloseIcon'
import { useTheme } from 'styled-components'

type Props = {
    transactionId: string
    timestamp?: number
    open: boolean
    handleOnClose: () => void
}

export const SingleTransactionAuditLog: FC<Props> = ({
    transactionId,
    timestamp,
    open,
    handleOnClose
      }) => {
  const store = useContext(StoreContext)
  const { singleTransactionAuditLog: trans } =
    store.TranslationsState.translations
  const theme = useTheme()

  const { selectedTenant } = store.AppState

  const [eventLogs, setEventLogs] = useState<EventLogsItem[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [pageTokenArray, setPageTokenArray] = useState<string[]>([''])

  const [fetching, setFetching] = useState(false)

  const setPageTokenByIndex = (index: number, token: string) => {
    const nextPageTokenArray = pageTokenArray
    nextPageTokenArray[index] = token

    setPageTokenArray(nextPageTokenArray)
  }

  const fetchEventLogs = useCallback(
    async (
      transactionId: string,
      timestamp: number,
      pageToken?: string
    ) => {
      if (selectedTenant?.length && selectedTenant !== 'null' && selectedTenant !== 'undefined') {
        const response = await axiosInstance.get<{
          items: EventLogsItem[]
          nextPageToken?: string
        }>('/audit-log/events/', {
          withCredentials: true,
          params: parseQueryParams({
            tenantId: selectedTenant,
            transactionId,
            timestamp,
            nextPageToken: pageToken && pageToken.length ? pageToken : undefined,
          }),
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          },
        })

        if (response.data.nextPageToken?.length) {
          setPageTokenByIndex(currentPageIndex + 1, response.data.nextPageToken)
        }

        setEventLogs([...eventLogs, ...response.data.items])
      }
    },
    [selectedTenant, currentPageIndex]
  )

  useEffect(() => {
    if (open) {
      setFetching(true)
      fetchEventLogs(
        transactionId,
        timestamp,
        pageTokenArray[currentPageIndex]
      )
      .finally(() => setFetching(false))
      .catch(() => toast(trans.unableToFetchAuditLog))
    }
  }, [open, currentPageIndex])

  useEffect(() => {
    setEventLogs([])
    setPageTokenArray([''])
    setCurrentPageIndex(0)
  }, [transactionId])

  const renderPagination = () => {
    if (!eventLogs?.length)
      return <Pagination justifyContent="center">{!fetching && <span>{trans.noResults}</span>}</Pagination>

    return (
      <Pagination justifyContent="center">
        <Button
          onClick={() => {
            setCurrentPageIndex(currentPageIndex + 1)
          }}
          disabled={currentPageIndex >= pageTokenArray.length - 1 || fetching}
          { ...theme.buttons.loadMoreButton }
        >
          {trans.loadMore}
        </Button>
      </Pagination>
    )
  }

  return (
    <StyledPage>
      <Page>
        <PageHeader>
          <TitleRefreshWrapper>
            <PageTitle>{trans.AuditLog}</PageTitle>
            <div
              onClick={() => {
                setEventLogs([])
                if (currentPageIndex === 0) {
                  setFetching(true)
                  fetchEventLogs(
                    transactionId,
                    timestamp,
                    pageTokenArray[currentPageIndex]
                  )
                  .finally(() => setFetching(false))
                  .catch(() => toast(trans.unableToFetchAuditLog))
                } else {
                  setPageTokenArray([''])
                  setCurrentPageIndex(0)
                }
              }}
            >
              <RefreshIcon />
            </div>
          </TitleRefreshWrapper>
          <CloseIcon onClick={handleOnClose}></CloseIcon>
        </PageHeader>
        <Spacer size={19} mobileSize={0}></Spacer>
        <TableContainer loader={fetching && !eventLogs.length}>
            {eventLogs.length > 0 && <AuditLogTable events={eventLogs} />}
            {fetching && <LoadingSpinner
              width="60px"
              {...theme.loadingSpinner} />}
        </TableContainer>
        <Spacer size={10} />
        {!fetching && renderPagination()}
      </Page>
    </StyledPage>
  )
}
