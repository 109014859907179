import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

export const StyledCreateTransaction = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
`

export const CreateTransactionIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledCreateTransaction>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        fill="currentColor"
      >
        <g>
          <path d="M6.5,8h9C15.776,8,16,7.776,16,7.5S15.776,7,15.5,7h-9C6.224,7,6,7.224,6,7.5S6.224,8,6.5,8z" />
          <path
            d="M3.5,18C2.673,18,2,18.673,2,19.5S2.673,21,3.5,21S5,20.327,5,19.5S4.327,18,3.5,18z M3.5,20C3.224,20,3,19.776,3,19.5
		S3.224,19,3.5,19S4,19.224,4,19.5S3.776,20,3.5,20z"
          />
          <path
            d="M3.5,14C2.673,14,2,14.673,2,15.5S2.673,17,3.5,17S5,16.327,5,15.5S4.327,14,3.5,14z M3.5,16C3.224,16,3,15.776,3,15.5
		S3.224,15,3.5,15S4,15.224,4,15.5S3.776,16,3.5,16z"
          />
          <path
            d="M3.5,10C2.673,10,2,10.673,2,11.5S2.673,13,3.5,13S5,12.327,5,11.5S4.327,10,3.5,10z M3.5,12C3.224,12,3,11.776,3,11.5
		S3.224,11,3.5,11S4,11.224,4,11.5S3.776,12,3.5,12z"
          />
          <path
            d="M3.5,6C2.673,6,2,6.673,2,7.5S2.673,9,3.5,9S5,8.327,5,7.5S4.327,6,3.5,6z M3.5,8C3.224,8,3,7.776,3,7.5S3.224,7,3.5,7
		S4,7.224,4,7.5S3.776,8,3.5,8z"
          />
          <path
            d="M24,7.62C24,6.175,22.824,5,21.378,5c-0.7,0-1.358,0.273-1.853,0.768l-1.379,1.379l0,0l0,0L18,7.293V3.5
		C18,2.673,17.327,2,16.5,2h-2.592c-0.207-0.581-0.757-1-1.408-1h-1.774C10.373,0.39,9.718,0,9,0S7.627,0.39,7.274,1H5.5
		C4.849,1,4.299,1.419,4.092,2H1.5C0.673,2,0,2.673,0,3.5v19C0,23.327,0.673,24,1.5,24h15c0.827,0,1.5-0.673,1.5-1.5v-7.794
		l3.85-3.85c0.001-0.001,0.002-0.001,0.003-0.002s0.001-0.002,0.002-0.003l1.377-1.377C23.728,8.979,24,8.321,24,7.62z
		 M13.765,17.527c-0.163-0.412-0.434-0.903-0.913-1.381c-0.477-0.477-0.967-0.749-1.379-0.911L18.5,8.208l2.292,2.292L13.765,17.527
		z M10.872,16.077c0.323,0.094,0.801,0.302,1.274,0.776c0.474,0.474,0.683,0.953,0.777,1.275l-2.736,0.685L10.872,16.077z M5.5,2
		h2.092c0.211,0,0.4-0.134,0.471-0.333C8.205,1.268,8.582,1,9,1s0.795,0.268,0.937,0.667C10.008,1.867,10.197,2,10.408,2H12.5
		C12.776,2,13,2.224,13,2.5V3H5V2.5C5,2.224,5.224,2,5.5,2z M17,22.5c0,0.276-0.224,0.5-0.5,0.5h-15C1.224,23,1,22.776,1,22.5v-19
		C1,3.224,1.224,3,1.5,3H4v0.5C4,3.776,4.224,4,4.5,4h9C13.776,4,14,3.776,14,3.5V3h2.5C16.776,3,17,3.224,17,3.5v4.793l-2.77,2.77
		C14.16,11.026,14.084,11,14,11H6.5C6.224,11,6,11.224,6,11.5S6.224,12,6.5,12h6.793l-3,3H6.5C6.224,15,6,15.224,6,15.5
		S6.224,16,6.5,16h3.36l-0.75,3H6.5C6.224,19,6,19.224,6,19.5S6.224,20,6.5,20h3c0.041,0,0.081-0.005,0.121-0.016l4-1
		c0.012-0.003,0.02-0.014,0.032-0.018c0.063-0.021,0.12-0.053,0.169-0.095c0.009-0.008,0.023-0.009,0.032-0.018L17,15.706V22.5z
		 M22.525,8.767l-1.026,1.026l-2.292-2.292l1.026-1.027c0.306-0.307,0.713-0.475,1.147-0.475c0.893,0,1.62,0.727,1.62,1.622
		C23,8.054,22.831,8.461,22.525,8.767z"
          />
        </g>
      </svg>
    </StyledCreateTransaction>
  )
}
