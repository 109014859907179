import styled, { css } from 'styled-components'

export const TagPillStyled = styled.div<{ withBackground: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    ~ span {
      margin-left: 5px;
    }
  }

  ${({ withBackground }) =>
    withBackground
      ? css`
          padding: 6px 10px;
          border-radius: 16px;
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.secondary4};
        `
      : css`
          color: ${({ theme }) => theme.colors.secondary2};
        `}
`
