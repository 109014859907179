import React, { ReactNode, useCallback, useMemo, useRef } from 'react'
import {
  lineNumbers,
  highlightActiveLineGutter,
  highlightSpecialChars,
  drawSelection,
  dropCursor,
  rectangularSelection,
  highlightActiveLine,
  keymap,
  EditorView,
} from '@codemirror/view'
import {
  foldGutter,
  indentOnInput,
  syntaxHighlighting,
  defaultHighlightStyle,
  bracketMatching,
  foldKeymap,
} from '@codemirror/language'
import { history, defaultKeymap, historyKeymap, indentWithTab } from '@codemirror/commands'
import { highlightSelectionMatches, searchKeymap } from '@codemirror/search'
import { closeBrackets, autocompletion, closeBracketsKeymap, completionKeymap } from '@codemirror/autocomplete'
// import { lintKeymap } from '@codemirror/lint'
import { Compartment, EditorState, Extension } from '@codemirror/state'
import { useEditorTheme } from './useEditorTheme'
import { useHighlightStyle } from './useHighlightStyle'
import { CodeMirror, CodeMirrorComponentProps, CodeMirrorProps } from '@ui-schema/kit-codemirror/CodeMirror'
import { useExtension } from '@ui-schema/kit-codemirror/useExtension'
import { json } from '@codemirror/lang-json'
import { lintKeymap } from '@codemirror/lint'

import { Container, JSONViewerTooltip } from './JSONEditor.styles'
import { toast } from 'react-toastify'
import { jsonLinter } from './lintPlugin'

interface StyledContainerProps {
  width?: string
  height?: string
  maxHeight?: string
  value?: string | object
  tooltipAdditionalElements?: ReactNode[]
  onLint?: (diagnostics: []) => void
}

export const JSONEditor: React.FC<Omit<CodeMirrorComponentProps, 'value'> & StyledContainerProps> = ({
  // Styled container props
  width,
  height,
  maxHeight,
  // values we want to override in this component
  value,
  effects,
  // additional buttons for tooltip
  tooltipAdditionalElements,
  // everything else is just passed down
  ...props
}) => {
  const { onChange, onLint } = props

  /* eslint-disable */
  const theme = useEditorTheme(typeof onChange === 'undefined')
  const highlightStyle = useHighlightStyle()
  const { init: initHighlightExt, effects: effectsHighlightExt } = useExtension(
    () => syntaxHighlighting(highlightStyle || defaultHighlightStyle, { fallback: true }),
    [highlightStyle]
  )
  const { init: initThemeExt, effects: effectsThemeExt } = useExtension(() => theme, [theme])
  const effectsRef = useRef<((editor: EditorView) => void)[]>(effects || [])

  const extensions = [json(), jsonLinter(onLint)]

  const extensionsAll: Extension[] = useMemo(
    () => [
      lineNumbers(),
      EditorView.lineWrapping,
      highlightActiveLineGutter(),
      highlightSpecialChars(),
      history(),
      foldGutter(),
      drawSelection(),
      dropCursor(),
      EditorState.allowMultipleSelections.of(true),
      new Compartment().of(EditorState.tabSize.of(4)),
      indentOnInput(),
      bracketMatching(),
      closeBrackets(),
      autocompletion(),
      rectangularSelection(),
      highlightActiveLine(),
      highlightSelectionMatches(),
      keymap.of([
        ...closeBracketsKeymap,
        ...defaultKeymap,
        ...searchKeymap,
        ...historyKeymap,
        ...foldKeymap,
        ...completionKeymap,
        ...lintKeymap,
        indentWithTab,
      ]),
      initHighlightExt(),
      initThemeExt(),
      ...(extensions || []),
    ],
    [initHighlightExt, initThemeExt]
  )

  // attach parent plugin effects first
  useMemo(() => {
    if (!effects) return effectsRef.current
    effectsRef.current.push(...effects)
  }, [effects])

  // attach each plugin effect separately (thus only the one which changes get reconfigured)
  useMemo(() => {
    if (!effectsHighlightExt) return
    effectsRef.current.push(...effectsHighlightExt)
  }, [effectsHighlightExt])
  useMemo(() => {
    if (!effectsThemeExt) return
    effectsRef.current.push(...effectsThemeExt)
  }, [effectsThemeExt])

  //eslint-disable-next-line
  const onViewLifecycle: CodeMirrorProps['onViewLifecycle'] = useCallback((view, destroyed) => {
    // console.log('on-view-lifecycle', view, destroyed)
  }, [])

  const computeValue = useCallback(() => {
    return typeof value === 'string' ? value : JSON.stringify(value, undefined, 4) || ''
  }, [value])

  const handleCopy = () => {
    navigator.clipboard.writeText(computeValue())
    toast.success('Copied to clipboard')
  }

  return (
    <Container $width={width} $height={height} $maxHeight={maxHeight}>
      <CodeMirror
        value={computeValue()}
        extensions={extensionsAll}
        onViewLifecycle={onViewLifecycle}
        effects={effectsRef.current.splice(0, effectsRef.current.length)}
        {...props}
      />
      <JSONViewerTooltip>
        {tooltipAdditionalElements}

        {/* <TooltipItem onClick={handleCopy}>
          <CopyIcon size="24" />
        </TooltipItem>
        */}
      </JSONViewerTooltip>
    </Container>
  )
}
