import { action, makeAutoObservable } from 'mobx'
import * as translations from '../constants/languages/index'

export type Language = 'en' | 'pl'

export interface TranslationsInterface {
  pageTitle: string
  loginPage: {
    welcomeBack: string
    enterEmail: string
    logIn: string
    redirecting: string
    pleaseWaitRedirectionToMicrosoft: string
  }
  errorPage: {
    somethingWentWrong: string
    logOut: string
  }
  menu: {
    selectTenant: string
    transactions: string
    createTransaction: string
    settings: string
    language: string
    theme: string
    logOut: string
  }
  transactionsPage: {
    pageTitle: string
    unableToFetchTransactions: string
    selectTenant: string
    setActiveTenant: string
    prev: string
    next: string
    of: string
    filters: string
    clientId: string
    transactionId: string
    configurationId: string
    applicationNumber: string
    externalReferenceId: string
    modularization: string
    status: string
    tags: string
    startDate: string
    endDate: string
    hideColumns: string
    clearFilters: string
    selectPlaceholder: string
    noResults: string
    all: string
    completed: string
    expired: string
    pending: string
    select: string
    notAuthorized: string
    notAuthorizedMessage: string
    copyToClipboard: string
  }
  singleTransactionPage: {
    goBack: string
    transaction: string
    unableToFetchTransaction: string
    unableToDeletePiiData: string
    notAuthorized: string
    notAuthorizedMessage: string
    basicInformation: string
    showAuditLog: string
    deletePiiData: string
    showTransactionHistory: string
    events: string
    steps: string
    deleteModalTitle: string
    confirm: string
    decline: string
  }
  singleTransactionAuditLog: {
    AuditLog: string
    unableToFetchAuditLog: string
    prev: string
    next: string
    noResults: string
    type: string
    publisher: string
    payload: string
    timestamp: string
    loadMore: string
  }
  singleTransactionHistory: {
    TransactionHistory: string
    unableToFetch: string
    noResults: string
    step: string
    timestamp: string
  }
  createTransactionPage: {
    goBack: string
    createTransaction: string
    noWriteRights: string
    jsonValidated: string
    errorinLine: string
    configurationID: string
    preferredLanguage: string
    themeId: string
    redirectURL: string
    phoneNumber: string
    email: string
    modularization: string
    PDFdocument: string
    uploadNewPDF: string
    transactionId: string
    proceedToken: string
    proceedUrl: string
    clientID: string
    payload: string
    newTransaction: string
    editConfiguration: string
    selectMock: string
    uploadPDFFile: string
    clickToSelectFile: string
    PDFfileInput: string
    pasteBase64: string
    existingPDFmocks: string
    pastePDFbase64: string
    PDFbase64Input: string
    clear: string
    create: string
    notAuthorized: string
    notAuthorizedMessage: string
  }
  settingsPage: {
    pageTitle: string
    tagsSettings: string
    icons: string
    tags: string
    both: string
    notAuthorized: string
    notAuthorizedMessage: string
    v3Settings: string
    on: string
    off: string
  }
}

const getInitialLanguage = () => {
  // const localLanguage: Language = localStorage.getItem('language') as Language

  // if (localLanguage)
  //   return localLanguage

  // const navigatorLanguage: Language = ((navigator.languages && navigator.languages[0]) || navigator.language)
  //   .split('-')[0] as Language

  // if (navigatorLanguage)
  //   return navigatorLanguage

  return 'en' as Language
}

export class TranslationsStore {
  rootStore
  language: Language = getInitialLanguage()
  translations: TranslationsInterface = translations[this.language] ?? translations.en

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setTranslations(language: Language): void {
    this.language = language
    this.translations = translations[language] ?? translations.en
    localStorage.setItem('language', language)
  }
}
