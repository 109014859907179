import { AppStateStore } from './AppState.store'
import { AuthStore } from './Auth.store'
import { FiltersStore } from './Filters.store'
import { SettingsStore } from './Settings.store'
import { MerchantsTableStore } from './MerchantsTable.store'
import { TranslationsStore } from './Translations.store'

export class RootStore {
  AppState: AppStateStore
  Auth: AuthStore
  Filters: FiltersStore
  Settings: SettingsStore
  MerchantsTable: MerchantsTableStore
  TranslationsState: TranslationsStore

  constructor() {
    this.AppState = new AppStateStore(this)
    this.Auth = new AuthStore(this)
    this.Filters = new FiltersStore(this)
    this.Settings = new SettingsStore(this)
    this.MerchantsTable = new MerchantsTableStore(this)
    this.TranslationsState = new TranslationsStore(this)
  }
}

export const rootStore = new RootStore()
