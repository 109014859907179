import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { LoadingSpinner } from 'authflow-ui-engine'
import qs from 'qs'
import { axiosInstance } from '../../methods/axiosConfig'
import { StoreContext } from '../../App'
import { Spacer } from '../../shared/components/Spacer'
import { TransactionHistoryTable } from './components/TransactionHistoryTable'
import { Page, PageHeader, PageTitle, StyledPage, TableContainer, TitleRefreshWrapper } from './TransactionsPage.styles'
import { TransactionHistoryItem } from './SingleTransactionPage.interface'
import { RefreshIcon } from './components/RefreshIcon'
import { toast } from 'react-toastify'
import { CloseIcon } from './components/CloseIcon'
import { useTheme } from 'styled-components'

type Props = {
  transactionId: string
  open: boolean
  handleOnClose: () => void
  isV3: boolean
}

export const SingleTransactionHistory: FC<Props> = ({ transactionId, open, handleOnClose, isV3 }) => {
  const store = useContext(StoreContext)
  const { singleTransactionHistory: trans } = store.TranslationsState.translations
  const theme = useTheme()

  const { selectedTenant } = store.AppState

  const [transactionHistory, setTransactionHistory] = useState<TransactionHistoryItem[]>([])

  const [fetching, setFetching] = useState(false)

  const fetchTransactionHistory = useCallback(async () => {
    if (selectedTenant?.length && selectedTenant !== 'null' && selectedTenant !== 'undefined') {
      const response = await axiosInstance.get<{
        items: TransactionHistoryItem[]
      }>(`/transactions/${transactionId}/history`, {
        withCredentials: true,
        params: {
          tenantId: selectedTenant,
          isV3,
        },
      })

      setTransactionHistory([
        ...transactionHistory,
        ...response.data.items.filter((step) => step.timestamp.length !== 0),
      ])
    }
  }, [selectedTenant])

  useEffect(() => {
    if (open) {
      setFetching(true)
      fetchTransactionHistory()
        .finally(() => setFetching(false))
        .catch(() => toast(trans.unableToFetch))
    }
  }, [open])

  return (
    <StyledPage>
      <Page>
        <PageHeader>
          <TitleRefreshWrapper>
            <PageTitle>{trans.TransactionHistory}</PageTitle>
            <div
              onClick={() => {
                setFetching(true)
                fetchTransactionHistory()
                  .finally(() => setFetching(false))
                  .catch(() => toast(trans.unableToFetch))
              }}
            >
              <RefreshIcon />
            </div>
          </TitleRefreshWrapper>
          <CloseIcon onClick={handleOnClose}></CloseIcon>
        </PageHeader>
        <Spacer size={19} mobileSize={0}></Spacer>
        <TableContainer loader={fetching}>
          {!fetching ? (
            <TransactionHistoryTable steps={transactionHistory} />
          ) : (
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          )}
        </TableContainer>
      </Page>
    </StyledPage>
  )
}
