import { FC } from 'react'
import { StyledDownloadJSONButton } from './DownloadJSONButton.styles'

interface DownloadJSONButtonProps {
  data: Record<string, any>
  filename?: string
}

export const DownloadJSONButton: FC<DownloadJSONButtonProps> = ({
  data,
  filename,
}) => {
  const downloadData = () => {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename || 'data.json'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <StyledDownloadJSONButton
      onClick={downloadData}
      title="Export as JSON file"
    >
      <svg version="1.1" id="Layer_1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 24 24" xmlSpace="preserve">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path d="M18.5,24c-3.032,0-5.5-2.467-5.5-5.5s2.468-5.5,5.5-5.5s5.5,2.467,5.5,5.5S21.532,24,18.5,24z M18.5,14
                      c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5S20.981,14,18.5,14z"/>
                  </g>
                </g>
                <g>
                  <path d="M18.5,22c-0.276,0-0.5-0.224-0.5-0.5v-6c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6C19,21.776,18.776,22,18.5,22z"
                    />
                </g>
                <g>
                  <path d="M18.5,22c-0.128,0-0.256-0.049-0.354-0.146l-2-2c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l1.646,1.646
                    l1.646-1.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-2,2C18.756,21.951,18.628,22,18.5,22z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path d="M11.5,22h-11C0.224,22,0,21.776,0,21.5v-15c0-0.133,0.053-0.26,0.146-0.354l6-6C6.24,0.053,6.367,0,6.5,0h11
                    C17.776,0,18,0.224,18,0.5v11c0,0.276-0.224,0.5-0.5,0.5S17,11.776,17,11.5V1H6.707L1,6.707V21h10.5c0.276,0,0.5,0.224,0.5,0.5
                    S11.776,22,11.5,22z"/>
                </g>
                <g>
                  <path d="M6.5,7h-6C0.224,7,0,6.776,0,6.5S0.224,6,0.5,6H6V0.5C6,0.224,6.224,0,6.5,0S7,0.224,7,0.5v6C7,6.776,6.776,7,6.5,7z"
                    />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>

    </StyledDownloadJSONButton>
  )
}
