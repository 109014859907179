const translationsReplaceValues = {
  externalUserRefId: {
    ikano: 'applicationNumber',
  },
}

export const checkTranslation = (value: string, tenant: string): string => {
  if (translationsReplaceValues[value] && tenant) {
    const selectedKey = Object.keys(translationsReplaceValues[value] as string).find((key: string) =>
      tenant.includes(key) ? key : undefined
    )

    return selectedKey ? (translationsReplaceValues[value][selectedKey] as string) : undefined
  }

  return
}

export function replaceValue(value: string, tenant: string) {
  if (value.includes('UnixTimeSeconds')) return value.replace('UnixTimeSeconds', 'Date')

  return checkTranslation(value, tenant) || value
}

export function paddedCamel(value: string) {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2')
}

function firstLetterUppercase(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const excludedWords: Record<string, string> = {
  URL: 'URL',
  EIDV: 'eIDV',
}

export function capitalizeEachWord(value: string): string {
  return value.replace(/\w\S*/g, (word: string) => {
    return word in excludedWords ? excludedWords[word] : firstLetterUppercase(word)
  })
}
