import styled from 'styled-components';
import StatusCanceledIcon from './statusCanceled.svg';
import StatusCompletedIcon from './statusCompleted.svg';
import StatusErrorIcon from './statusError.svg';
import StatusExpiredIcon from './statusExpired.svg';
import StatusPendingIcon from './statusPending.svg';


const handleStatusIcon = status => {
    switch (status) {
        case 'canceled':
            return StatusCanceledIcon;
        case 'completed':
            return StatusCompletedIcon;
        case 'error':
            return StatusErrorIcon;
        case 'expired':
            return StatusExpiredIcon;
        case 'pending':
            return StatusPendingIcon;
      
      default:
        return null;
    }
  };

export const StyledStatus = styled.div<{
  status: string
}>`
    color: ${({ theme }) => theme.colors.senary};
    font-size: 0.88rem;
    padding-left: 15px;
    background-position: 0;
    background-repeat: no-repeat;
    background-image: url('${({ status }) => { return handleStatusIcon(status)}}');
`
