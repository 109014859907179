export const getReadableDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const datevalues = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    time: date.toTimeString().substring(0, 8),
  }

  return datevalues
}

export const getFormatedDate = (timestamp: number) => {
  const datevalues = getReadableDate(timestamp)
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return `${datevalues.year}-${('0' + datevalues.month).slice(-2)}-${('0' + datevalues.day).slice(-2)} ${
    datevalues.time
  }`
}
