import { FC, PropsWithChildren } from 'react'
import { TableGroupingStyled } from './TableGrouping.styles'
import { useTheme } from 'styled-components'

type Props = {
  grouped?: boolean
  tooltip: string
}

export const TableGrouping: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <TableGroupingStyled title={props.tooltip}>
      {props.grouped ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill={theme.colors.secondary2} />
          </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
          <path d="M11.5 4H0.5V6H11.5V4ZM11.5 0H0.5V2H11.5V0ZM15.5 8V4H13.5V8H9.5V10H13.5V14H15.5V10H19.5V8H15.5ZM0.5 10H7.5V8H0.5V10Z" fill={theme.colors.secondary2} />
        </svg>
      )} 
      
    </TableGroupingStyled>
  )
}
